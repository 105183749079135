<template>
  <header>
    <nav class="index-green-color">
      <div class="nav-wrapper">
        <div class="brand-logo left header-logo">
          <img src="../assets/img/logo.png" alt="logo" class="img-logo" />
          <p class="title-logo">Система автоматизированного освещения</p>
        </div>

        <ul id="nav-mobile" class="right hide-on-med-and-down">
          <li
            class="modal-trigger"
            data-target="modalHistory"
            @click="getHistoryAll()"
          >
            <router-link :to="'/'"><p>История</p> </router-link>
          </li>
          <li class="modal-trigger" data-target="modalAboutProgram">
            <router-link :to="'/'"><p>О программе</p> </router-link>
          </li>

          <li>
            <a href="" @click="exit()"> <p>Выход</p> </a>
            <!-- <router-link :to="'/login'" @click="exit()"
              ><p>Выход</p>
            </router-link> -->
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { actionTypes } from "@/store/modules/auth";
import { actionTypesHistory } from "@/store/modules/history";
import { actionTypesSocket } from "@/store/modules/socket";
export default {
  name: "AppHeader",
  methods: {
    exit() {
      this.$store
        .dispatch(actionTypes.exitLogin)
        .then(() => {
          this.$store.dispatch(actionTypesSocket.socketClose);
          // socketClose
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    getHistoryAll() {
      this.$store
        .dispatch(actionTypesHistory.getHistoryLogs)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
};
</script>

<style>
/* header {
  padding-left: 300px;
} */
</style>
