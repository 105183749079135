import demirAPi from "@/api/demir.js";
// import { setItem } from "@/helpers/persistanceStorage";
// import axios from "axios";
// import router from "@/router";

const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  allDemir: [],
  checkedDemir: [],
  property: null,
};

export const mutationTypes = {
  //получение блоов демирования
  getAllDemirStart: "[demir] getAllDemirStart",
  getAllDemirSuccess: "[demir] getAllDemirSuccess",
  getAllDemirFailure: "[demir] getAllDemirFailure",
  //Изменение имени демирования
  editNameDemirStart: "[demir] editNameDemirStart",
  editNameDemirSuccess: "[demir] editNameDemirSuccess",
  editNameDemirFailure: "[demir]  editNameDemirFailure",

  //Получение одного измененного объекта
  getNewDemirStart: "[demir] getNewDemirStart",
  getNewDemirSuccess: "[demir] getNewDemirSuccess",
  getNewDemirFailure: "[demir]  getNewDemirFailure",

  //Получение дополнительных парамметров
  getPropertyStart: "[demir] getPropertyStart",
  getPropertySuccess: "[demir] getPropertySuccess",
  getPropertyFailure: "[demir]  getPropertyFailure",

  postSetSettingsStart: "[demir] postSetSettingsStart",
  postSetSettingsSuccess: "[demir] postSetSettingsSuccess",
  postSetSettingsFailure: "[demir] postSetSettingsFailure",

  postSetAllSettingsStart: "[demir] postSetAllSettingsStart",
  postSetAllSettingsSuccess: "[demir] postSetAllSettingsSuccess",
  postSetAllSettingsFailure: "[demir] postSetAllSettingsFailure",

  checkedDemirSuccess: "[demir] checkedDemirSuccess",
};
export const actionTypesDemir = {
  getAllDemir: "[demir] getAllDemir",
  checkedDemir: "[demir] checkedDemir",
  editNameDemir: "[demir] editNameDemir",
  getNewDemir: "[demir] getNewDemir",
  getProperty: "[demir] getProperty",
  postSetSettings: "[dimir] postSetSettings",
  postSetAllSettings: "[dimir] postSetAllSettings",
};

const mutations = {
  [mutationTypes.getAllDemirStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getAllDemirSuccess](state, payload) {
    state.isLoading = false;
    state.allDemir = payload;
  },
  [mutationTypes.getAllDemirFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Изменение имени демирования
  [mutationTypes.editNameDemirStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.editNameDemirSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.editNameDemirFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Получение одного измененного объекта
  [mutationTypes.getNewDemirStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  //замена элементов блоков демирования
  [mutationTypes.getNewDemirSuccess](state, payload) {
    console.log(payload, "замена");
    state.isLoading = false;
    let newElem = state.allDemir.find((element) => {
      return element.id === payload.id;
    });
    var indexNewElem = state.allDemir.indexOf(newElem);
    state.allDemir.splice(indexNewElem, 1, payload);
  },
  [mutationTypes.getNewDemirFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // получение дополнительной информации
  [mutationTypes.getPropertyStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getPropertySuccess](state, payload) {
    state.isLoading = false;
    state.property = payload;
  },
  [mutationTypes.getPropertyFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // Изменение настроек блоков демирования
  [mutationTypes.postSetSettingsStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.postSetSettingsSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postSetSettingsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // Изменение настроек выбранных блоков
  [mutationTypes.postSetAllSettingsStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.postSetAllSettingsSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postSetAllSettingsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //отображение на карте блоков демирования
  [mutationTypes.checkedDemirSuccess](state, payload) {
    state.checkedDemir = payload;
  },
};
const actions = {
  [actionTypesDemir.getAllDemir](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllDemirStart);
      demirAPi
        .getAllDemir()
        .then((response) => {
          context.commit(mutationTypes.getAllDemirSuccess, response.data);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.getAllDemirFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypesDemir.editNameDemir](context, credentials) {
    return new Promise((resolve) => {
      // console.log(credentials);
      context.commit(mutationTypes.editNameDemirStart);
      demirAPi
        .editNameDemir(credentials)
        .then((response) => {
          context.commit(mutationTypes.editNameDemirSuccess);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.editNameDemirFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypesDemir.getNewDemir](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNewDemirStart);
      demirAPi
        .getNewDemir(credentials)
        .then((response) => {
          context.commit(mutationTypes.getNewDemirSuccess, response.data);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.getNewDemirFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypesDemir.getProperty](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getPropertyStart);
      demirAPi
        .getProperty(credentials)
        .then((response) => {
          context.commit(mutationTypes.getPropertySuccess, response.data);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.getPropertyFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypesDemir.postSetSettings](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.postSetSettingsStart);
      demirAPi
        .postSetSettings(credentials)
        .then((response) => {
          context.commit(mutationTypes.postSetSettingsSuccess, response.data);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.postSetSettingsFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypesDemir.postSetAllSettings](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.postSetAllSettingsStart);
      demirAPi
        .postSetAllSettings(credentials)
        .then((response) => {
          context.commit(
            mutationTypes.postSetAllSettingsSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(
            mutationTypes.postSetAllSettingsFailure,
            response.data
          );
          console.log("ошибка", response);
        });
    });
  },

  [actionTypesDemir.checkedDemir](context, credentials) {
    context.commit(mutationTypes.checkedDemirSuccess, credentials);
  },
};
export default {
  state,
  mutations,
  actions,
};
