import historyAPi from "@/api/history.js";

const state = {
  isSubmitting: false,
  historyAll: [],
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
};

export const mutationTypes = {
  getHistoryLogsStart: "[history] getHistoryLogsStart",
  getHistoryLogsSuccess: "[history] getHistoryLogsSuccess",
  getHistoryLogsFailure: "[history] lgetHistoryLogsailure",
};
export const actionTypesHistory = {
  getHistoryLogs: "[history] getHistoryLogs",
};

const mutations = {
  [mutationTypes.getHistoryLogsStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getHistoryLogsSuccess](state, payload) {
    state.isLoading = false;
    state.historyAll = payload;
  },
  [mutationTypes.getHistoryLogsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesHistory.getHistoryLogs](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getHistoryLogsStart);
      historyAPi
        .getHistoryLogs()
        .then((response) => {
          context.commit(mutationTypes.getHistoryLogsSuccess, response.data);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.getHistoryLogsFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
