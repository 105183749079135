<template>
  <div class="shema-block mint-green-color z-depth-2 margin-sb">
    <div class="shema-block__title">
      <div class="shema-block__title-wrap">
        <label class="shema-block__checkbox-wrap">
          <input
            type="checkbox"
            class="filled-in"
            :value="item_data"
            @change="onChange()"
            v-model="checkedDemir"
          />

          <span class=""></span>
        </label>
        <form v-if="this.isEditName === true" class="shema-block__form-edit">
          <input
            id="last_name"
            type="text"
            class="validate shema-block__input-edit"
            name="nameDimmer"
            :value="this.item_data.name"
          />
        </form>
        <p
          v-if="this.isEditName === false && this.item_data.name"
          @click="zoomToMarker()"
          class="title-text"
        >
          {{ this.item_data.name }}
        </p>
        <p
          class="title-text-empty"
          v-else-if="this.isEditName === false"
          @click="zoomToMarker()"
        >
          Введите название блока
        </p>
      </div>

      <ul id="dropdownMenuDe" class="dropdown-content">
        <li
          class="modal-trigger"
          data-target="modalInfoDimer"
          style="display: flex; align-items: center"
        >
          <span class="size-text-14 grey-text text-darken-4"
            >Запрос состояния</span
          >
        </li>

        <hr />
        <li
          class="modal-trigger"
          data-target="modalSettingsDemmir"
          style="display: flex; align-items: center"
          @click="clickSettings()"
        >
          <span class="size-text-14 grey-text text-darken-4"
            >Настройки демирования</span
          >
        </li>
      </ul>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="edit"
        class="svg-inline--fa fa-edit fa-w-18 shema-block__svg-edit"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        v-if="this.isEditName === false"
        @click="editNameTimer"
      >
        <path
          fill="white"
          d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
        ></path>
      </svg>
      <svg
        viewBox="0 0 256 256"
        class="shema-block__svg-check"
        v-if="this.isEditName === true"
        @click="changeNameDemir()"
      >
        <rect width="25" height="25" fill="none" />
        <polyline
          fill="none"
          stroke="#a7ffeb"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
          points="216 72.005 104 184 48 128.005"
        />
      </svg>

      <i
        class="material-icons dropdown-trigger"
        style="color: white; cursor: pointer"
        data-target="dropdownMenuDe"
        @click="handleClick()"
        role="button"
        aria-label="Menu"
        >more_vert</i
      >
    </div>
    <li><div class="divider"></div></li>
    <div class="shema-block__info" v-bind:class="classObject">
      <!-- <div class="" style="margin-left: 94px; height: 2px" v-if="this.loader">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> -->
      <p class="info-text">
        <!-- Адрес: -->
        <span class="add-text"
          >{{ this.place.road }} {{ this.place.house_number }}
          {{ this.place.city_district }}
        </span>
        <span class="add-text">{{ this.fullPlace }}</span>
      </p>

      <p class="info-text">
        Мощность:
        <span class="add-text">{{ this.item_data.currentPower }} %</span>
      </p>
      <p class="info-text">
        Активность:
        <span class="add-text-success" v-bind:class="messageColor">{{
          newStr
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import axios from "axios";
import { actionTypesDemir } from "@/store/modules/demir";
import { mapState } from "vuex";
// import ModalBlock from "./ModalBlock.vue";
export default {
  name: "AppItemDimirList",
  data() {
    return {
      modalInstance: null,
      modalTooltip: null,
      place: {},
      checkBlock: "",
      isEditName: false,
      checkedP: false,
      newNameDemir: [],
      fullPlace: "",
      loader: true,

      // status: "",
    };
  },
  props: {
    item_data: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Array,
      default() {
        return {};
      },
    },
  },

  methods: {
    handleClick() {
      //  getProperty
      this.$store
        .dispatch(actionTypesDemir.getProperty, this.item_data.dimmerId)
        .then((resp) => {
          console.log(resp, "resp get prop");
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });

      // console.log(this.item_data, "handleClick");
      // this.$emit("itemData", this.item_data);
    },
    clickSettings() {
      console.log("clickSettings");
      this.$emit("clickSettings");
    },
    handleClickTimer() {
      console.log("handleClickTimer");
      this.$emit("itemDataHistory", this.item_data);
    },
    zoomToMarker() {
      console.log(this.item_data, "Click marker");
      this.$emit("zoomToMarker", this.item_data);
    },
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheckDimer");
    },
    editNameTimer() {
      this.isEditName = !this.isEditName;
    },
    changeNameDemir() {
      this.isEditName = !this.isEditName;
      const forms = document.forms;
      const formData = new FormData(forms[1]);
      this.newNameDemir = [];
      for (let [name, value] of formData) {
        this.newNameDemir = { ...this.newNameDemir, [name]: value };
        // console.log(`${name}: ${value}`);
      }
      this.newNameDemir = { ...this.newNameDemir, id: this.item_data.id };
      // console.log(this.newNameDemir, "this.newNameDemir");
      //ЗАПРОС НА ИЗМЕНЕНИЕ ИМЕНИ

      this.$store
        .dispatch(actionTypesDemir.editNameDemir, this.newNameDemir)
        .then(() => {
          this.$store.dispatch(
            actionTypesDemir.getNewDemir,
            this.item_data.dimmerId
          );
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },

  computed: {
    ...mapState({
      messageStatus: (state) => state.socket.message,
    }),
    checkStatus() {
      if (this.item_data.status === true) {
        return "Включено";
      } else {
        return "Отключено";
      }
    },
    classObject: function () {
      return {
        noactive: this.checkStatus === "Отключено",
      };
    },
    messageColor: function () {
      return {
        urgently: this.item_data.difference,
      };
    },
    checkedDemir: {
      get() {
        return this.value;
      },
      set(val) {
        this.checkedP = val;
      },
    },
    time: function () {
      return this.item_data.lastActivity.split(" ");
    },
    newStr: function () {
      if (
        this.time[0] === "0" &&
        this.time[1] === "00" &&
        this.time[2] === "00"
      ) {
        return this.time[3] + "с " + "назад";
      } else if (
        this.time[0] === "0" &&
        this.time[1] === "00" &&
        this.time[2] === "00" &&
        this.time[3] === "00"
      ) {
        return "Обновлено сейчас";
      } else if (this.time[0] === "0" && this.time[1] === "00") {
        return this.time[2] + "м " + this.time[3] + "с " + "назад";
      } else if (this.time[0] === "0") {
        return (
          this.time[1] +
          "ч " +
          this.time[2] +
          "м " +
          this.time[3] +
          "с " +
          "назад"
        );
      } else
        return (
          this.time[0] +
          "д " +
          this.time[1] +
          "ч " +
          this.time[2] +
          "м " +
          this.time[3] +
          "с " +
          "назад"
        );
    },
  },
  mounted() {
    // this.loader == true;
    const elems = document.querySelectorAll(".dropdown-trigger");
    this.modalInstance = M.Dropdown.init(elems);

    var elemsTool = document.querySelectorAll(".tooltipped");
    this.modalTooltip = M.Tooltip.init(elemsTool);

    this.searchCoord = {
      lat: this.item_data.coordinate[0],
      lon: this.item_data.coordinate[1],
    };
    axios
      .get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.searchCoord.lat}&lon=${this.searchCoord.lon}&zoom=18&addressdetails=1`
      )
      .then((response) => {
        this.loader = !this.loader;
        console.log(response.data.address, "response");
        if (
          response.data.address.road ||
          response.data.address.city_district ||
          response.data.address.house_number
        ) {
          this.place = {
            road: response.data.address.road,
            city_district: response.data.address.city_district,
            house_number: response.data.address.house_number,
          };
        } else this.fullPlace = response.data.display_name;
        // this.place = response.data.display_name;
      })
      .catch(() => {
        this.loader = !this.loader;
        this.place = "Место не найдено";
      });
  },
};
</script>

<style scoped>
.dropdown-content li {
  padding: 5px 8px 5px 8px !important;
}
.noactive {
  background-color: #e0e0e0;
  /* ackground-color: #e0e0e0;  */
}
</style>
