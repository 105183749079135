<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import M from "materialize-css";
import axios from "axios";
import { actionTypes } from "@/store/modules/auth";

export default {
  name: "App",
  data() {
    return {
      stompClient: {},
      isConnect: false,
      messageDimmer: "",
      message: 1,
    };
  },

  created: function () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(actionTypes.exitLogin);
        }
        throw err;
      });
    });
  },
  mounted() {
    M.AutoInit();
  },
};
</script>
