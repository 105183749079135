<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalChange" class="modal">
      <form>
        <div class="modal-content">
          <div class="modal-info__title-block mint-green-color">
            <p class="modal-info__title">
              Изменение координат
              <!-- <a class="icon-close" @click="closeModal()"
              ><i class="material-icons">close</i></a
            > -->
            </p>
            <div class="modal-info__close modal-close">
              <i class="material-icons white-text"> close</i>
            </div>
          </div>

          <div
            class="modal-info__block-power-coordinate"
            v-if="this.copyCoordinate !== null"
          >
            <p>Назначить широту</p>
            <div class="input-field col s6">
              <input
                id="lang"
                type="text"
                class="validate"
                :value="copyCoordinate[0].lat"
              />
            </div>

            <p>Назначить долготу</p>
            <div class="input-field col s6">
              <input
                id="lon"
                type="text"
                class="validate"
                :value="copyCoordinate[0].lng"
              />
            </div>
          </div>
          <div class="modal-info__block-power-coordinate" v-else>
            <p class="modal-title__error red-text text-darken-1">
              Координаты не были скопированны*
            </p>
            <p>Назначить широту</p>
            <div class="input-field col s6">
              <input
                id="lang"
                type="number"
                :class="isValidate && !this.lat.length ? 'error-input' : ''"
                class="validate"
                v-model="lat"
              />
            </div>

            <p>Назначить долготу</p>
            <div class="input-field col s6">
              <input
                id="lon"
                type="number"
                :class="isValidate && !this.lng.length ? 'error-input' : ''"
                class="validate"
                v-model="lng"
              />
            </div>
          </div>
          <div class="modal-btn">
            <button
              type="button"
              @click="onSubmit()"
              name="btn_login"
              :class="!this.isValidate ? 'modal-close' : ''"
              class="col s12 btn btn-large waves-effect mint-green-color"
            >
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";

import { actionTypes } from "@/store/modules/lights";

import { mapState } from "vuex";
export default {
  name: "AppModalChangeCoordinates",
  data() {
    return {
      modalInstance: null,
      lat: "",
      lng: "",
      isValidate: false,
    };
  },
  components: {},
  props: {
    item_light: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      copyCoordinate: (state) => state.lights.copyCoordinate,
    }),
  },

  methods: {
    handleClick() {
      this.modalInstance.close();
    },
    onSubmit() {
      this.isValidate = false;
      if (this.copyCoordinate === null && this.lat.length && this.lng.length) {
        this.$store
          .dispatch(actionTypes.changeCoordinateBlock, {
            imei: this.item_light.imei,
            latitude: this.lat,
            longitude: this.lng,
          })
          .then((resp) => {
            console.log(resp);
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      } else if (
        this.copyCoordinate === null &&
        !this.lat.length &&
        !this.lng.length
      ) {
        this.isValidate = true;
      } else {
        this.$store
          .dispatch(actionTypes.changeCoordinateBlock, {
            imei: this.item_light.imei,
            latitude: this.copyCoordinate[0].lat,
            longitude: this.copyCoordinate[0].lng,
          })
          .then((resp) => {
            console.log(resp);
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
  },
  mounted() {
    const modalss = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modalss);

    M.updateTextFields();
  },
};
</script>
<style scoped>
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 20px 0px;
}
</style>
