<template>
  <div class="">
    <div class="modal-info__block-power">
      <div class="modal-settings__alarm-clock-value">
        <div class="modal-title">{{ this.item_settings.nameClockTitle }}</div>
        <vue-timepicker
          format="HH:mm:ss"
          :name="this.item_settings.nameClock"
          v-model="alarmClock"
        ></vue-timepicker>
      </div>
      <div class="modal-settings__alarm-clock-power">
        <div class="modal-title-power">
          Процент освещения {{ this.settings }} %
        </div>
        <p class="range-field">
          <input
            type="range"
            id="range"
            :name="this.item_settings.namePower"
            min="0"
            max="100"
            :step="1"
            v-model="settings"
          />
        </p>
      </div>
    </div>
    <div class="modal-settings__button-delete">
      <button
        type="button"
        class="btn btn-danger red lighten-1"
        style="color: white !important"
        @click="$emit('remove')"
      >
        Удалить будильник
      </button>
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
export default {
  name: "AppSettingsDemir",
  props: {
    item_settings: {
      type: Object,
      default() {
        return {};
      },
    },
    property: {
      type: Object,
      default() {
        return {};
      },
    },
    val: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    alarmClock: {
      get() {
        if (
          eval("this.property." + this.item_settings.nameClock) !== "00:00:00"
        ) {
          return eval("this.property." + this.item_settings.nameClock);
        } else {
          return "00:00:00";
        }
      },
      set(val) {
        if (
          eval("this.property." + this.item_settings.nameClock) !== "00:00:00"
        ) {
          return eval("this.property." + this.item_settings.nameClock);
        } else {
          return val;
        }
      },
    },
    alarmPower: {
      get() {
        if (eval("this.property." + this.item_settings.namePower) !== 0) {
          return eval("this.property." + this.item_settings.namePower);
        } else {
          return this.settings;
        }
      },
      set(val) {
        if (eval("this.property." + this.item_settings.namePower) !== 0) {
          return (this.settings = val);
        } else {
          return (this.settings = val);
        }
      },
    },
    // settings: {
    //   get() {
    //     return eval("this.property." + this.item_settings.namePower);
    //   },
    //   set(val) {
    //     return val;
    //   },
    // },
  },
  data() {
    return {
      settings: eval("this.property." + this.item_settings.namePower),
    };
  },
  components: {
    VueTimepicker,
  },
  methods: {},
};
</script>

<style></style>
