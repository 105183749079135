<template>
  <div class="">
    <div class="modal-info__block-power">
      <div class="modal-settings__alarm-clock-value">
        <div class="modal-title">{{ this.item_settings.nameClockTitle }}</div>
        <vue-timepicker
          format="HH:mm:ss"
          :name="this.item_settings.nameClock"
        ></vue-timepicker>
      </div>
      <div class="modal-settings__alarm-clock-power">
        <div class="modal-title-power">
          Процент освещения {{ this.settings }} %
        </div>
        <p class="range-field">
          <input
            type="range"
            id="range"
            :name="this.item_settings.namePower"
            min="0"
            max="100"
            :step="1"
            v-model="settings"
          />
        </p>
      </div>
    </div>
    <div class="modal-settings__button-delete">
      <button
        type="button"
        class="btn btn-danger red lighten-1"
        style="color: white !important"
        @click="$emit('remove')"
      >
        Удалить будильник
      </button>
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
export default {
  name: "AppSettingsAllDemir",
  data() {
    return {
      settings: 0,
    };
  },
  props: {
    item_settings: {
      type: Object,
      default() {
        return {};
      },
    },

    val: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  components: {
    VueTimepicker,
  },
};
</script>

<style></style>
