<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalInfoDimer" class="modal">
      <div class="modal-content">
        <div class="modal-info__title-block mint-green-color">
          <p class="modal-info__title">
            Информация:
            <!-- <a class="icon-close" @click="closeModal()"
              ><i class="material-icons">close</i></a
            > -->
          </p>
          <div class="modal-info__close modal-close">
            <i class="material-icons white-text"> close</i>
          </div>
        </div>
        <div class="modal-info__info" v-if="this.property !== null">
          <div class="modal-info__wrap-info">
            <div class="modal-info__left-block">
              <div class="block__about-timer">
                <div class="">
                  <p class="modal-info__text-timer center">
                    Время работы таймера №1:
                  </p>
                  <p class="modal-info__text-times center">
                    {{ this.property.alarmClockValue1 }} -
                    {{ this.property.alarmClockPower1 }}%
                  </p>
                </div>
                <div class="">
                  <p class="modal-info__text-timer center">
                    Время работы таймера №2:
                  </p>
                  <p class="modal-info__text-times center">
                    {{ this.property.alarmClockValue2 }} -
                    {{ this.property.alarmClockPower2 }}%
                  </p>
                </div>
                <div class="">
                  <p class="modal-info__text-timer center">
                    Время работы таймера №3:
                  </p>
                  <p class="modal-info__text-times center">
                    {{ this.property.alarmClockValue3 }} -
                    {{ this.property.alarmClockPower3 }}%
                  </p>
                </div>
                <div class="">
                  <p class="modal-info__text-timer center">
                    Время работы таймера №4:
                  </p>
                  <p class="modal-info__text-times center">
                    {{ this.property.alarmClockValue4 }} -
                    {{ this.property.alarmClockPower4 }}%
                  </p>
                </div>
                <div class="">
                  <p class="modal-info__text-timer center">
                    Время работы таймера №5:
                  </p>
                  <p class="modal-info__text-times center">
                    {{ this.property.alarmClockValue5 }} -
                    {{ this.property.alarmClockPower5 }}%
                  </p>
                </div>
                <div class="">
                  <p class="modal-info__text-timer center">
                    Время работы таймера №6:
                  </p>
                  <p class="modal-info__text-times center">
                    {{ this.property.alarmClockValue6 }} -
                    {{ this.property.alarmClockPower6 }}%
                  </p>
                </div>
                <!-- <p class="modal-info__text">
                  Часовой пояс:
                  <span>+{{ this.item_light.timezone }}</span>
                </p> -->
              </div>
              <div class="block__about-block">
                <p class="modal-info__text">
                  Температура на устройстве:
                  <span>{{ this.property.temperature }} °C </span>
                </p>
                <p class="modal-info__text">
                  Текущая мощность выхода в процентах:
                  <span> {{ this.property.currentPower }} %</span>
                </p>
                <!-- <p class="modal-info__text">
                  Время последней активности:
                  <span>{{ newStr }}</span>
                </p> -->

                <p class="modal-info__text">
                  Частота обновления сигнала:
                  <span> {{ this.property.intervalUpdateRadioSignal }}</span>
                </p>
                <p class="modal-info__text">
                  Мощность радиосигнала:
                  <span> {{ this.property.powerRadioSignal }}</span>
                </p>
                <p class="modal-info__text">
                  Уровень радиосигнала:
                  <span> {{ this.property.rssi }}</span>
                </p>
                <p class="modal-info__text">
                  Напряжение:
                  <span> {{ this.property.volt }} В</span>
                </p>
                <p class="modal-info__text">
                  Текущее время таймера:
                  <span>{{ this.property.currentDateTime }}</span>
                </p>
                <Clock />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";

import Clock from "./Clock.vue";
import { mapState } from "vuex";
export default {
  name: "AppModalInfoDimer",
  data() {
    return {
      modalInstance: null,
      power: 1,
    };
  },

  components: {
    Clock,
  },
  computed: {
    ...mapState({
      property: (state) => state.demir.property,
    }),
  },
  methods: {
    handleClick() {
      this.modalInstance.close();
    },
  },
  mounted() {
    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);
    const elems = document.querySelectorAll("input[type=range]");
    M.Range.init(elems);

    const select = document.querySelector("select");
    M.FormSelect.init(select);

    M.updateTextFields();
    //  M.Modal.init();
    //  M.Modal.AutoInit();
  },
};
</script>
<style scoped>
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.font {
  font-weight: 500;
}
</style>
