<template>
  <div class="block-clock">
    <div class="clock-border">
      <!-- <p class="modal-info__text-clock">Текущее время:</p> -->
      <div class="clock-inner" :class="color">
        <div class="hour">{{ hours }}</div>
        <div class="dots">:</div>
        <div class="min">{{ minutes }}</div>
        <div class="dots">:</div>
        <div class="secs">{{ seconds }}</div>
        <div class="mode"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DigitalClock",
  props: {
    color: {
      type: String,
      default: "grey lighten-2",
    },
  },
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  methods: {
    setTime() {
      setInterval(() => {
        const date = new Date();
        this.hours = date.getHours();
        this.minutes = this.checkSingleDigit(date.getMinutes());
        this.seconds = this.checkSingleDigit(date.getSeconds());
      }, 1000);
    },
    checkSingleDigit(digit) {
      return ("0" + digit).slice(-2);
    },
  },
  mounted() {
    this.setTime();
  },
};
</script>

<style>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
}
.hour,
.min,
.secs {
  font-size: 2em;
}
strong {
  color: blue;
}
.block-clock {
  margin: 10px 0px;
}
.clock-border {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 700px; */
  /* height: 200px; */
  /* background: linear-gradient(to right, grey, rgb(82, 76, 76)); */
}
.clock-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 600px; */
  /* height: 150px; */
  /* background: black; */
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  color: black !important;
}
.dots {
  font-size: 30px;
}
.red {
  color: red;
}
.yellow {
  color: yellow;
}
.green {
  color: white;
}
.blue {
  color: white;
}
.grey {
  color: #e0e0e0;
}
</style>
