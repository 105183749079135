<template>
  <div>
    <!-- Modal Structure -->
    <div id="modal1" class="modal">
      <div class="modal-content" v-if="this.item_light !== null">
        <div class="modal-info__title-block mint-green-color">
          <p class="modal-info__title">
            Информация: {{ this.item_light.nameTimer }}
            <!-- <a class="icon-close" @click="closeModal()"
              ><i class="material-icons">close</i></a
            > -->
          </p>
          <div class="modal-info__close modal-close">
            <i class="material-icons white-text"> close</i>
          </div>
        </div>
        <div class="modal-info__info">
          <div class="modal-info__wrap-info">
            <div class="modal-info__left-block">
              <div class="block__about-timer">
                <p class="modal-info__text-timer center">
                  Время работы таймера:
                </p>
                <p class="modal-info__text-times center">
                  {{ this.item_light.startTime }} -
                  {{ this.item_light.endTime }}
                </p>
                <p class="modal-info__text">
                  Часовой пояс:
                  <span>+{{ this.item_light.timezone }}</span>
                </p>

                <p class="modal-info__text">
                  Текущее время таймера:
                  <span>{{ this.item_light.currentTime }}</span>
                </p>
                <Clock />
              </div>
              <div class="block__about-block">
                <p class="modal-info__text" v-if="this.item_light.type === 1">
                  Текущее значение: <span>{{ this.item_light.value }}</span>
                </p>
                <p class="modal-info__text">
                  Адрес:
                  <span>Скоро появится{{ this.item_light.address }}</span>
                </p>
                <p class="modal-info__text">
                  Температура на устройстве:
                  <span>{{ this.item_light.temperature }} °C </span>
                </p>
                <p class="modal-info__text">
                  Уровень сигнала:
                  <span> {{ this.item_light.levelSignal }} %</span>
                </p>
                <p class="modal-info__text" v-if="this.item_light.type === 0">
                  Статус: <span>{{ this.checkStatus }}</span>
                </p>
                <p class="modal-info__text">
                  Время последней активности:
                  <span>{{ newStr }}</span>
                </p>
                <p class="modal-info__text">
                  Защита двери:
                  <span v-if="this.item_light.securityDoor">Защищено</span>
                  <span v-else>Вскрыто</span>
                </p>
                <p class="modal-info__text">
                  Телефон:
                  <span> {{ this.item_light.phone }}</span>
                </p>
                <p class="modal-info__text">
                  IMEI:
                  <span> {{ this.item_light.imei }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button
          type="button"
          name="btn_login"
          class="modal-close col s12 btn btn-large waves-effect deep-orange accent-2"
        >
          Изменить
        </button> -->
      <!-- <a href="#!" class="modal-close waves-effect waves-green btn-flat"
          >Изменить</a
        > -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import M from "materialize-css";

import Clock from "./Clock.vue";
export default {
  name: "AppModalBlock",
  data() {
    return {
      modalInstance: null,
      power: 1,
    };
  },
  props: {
    item_light: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Clock,
  },
  computed: {
    checkStatus() {
      if (this.item_light.status === 1) {
        return "Включено";
      } else {
        return "Отключено";
      }
    },
    time: function () {
      if (this.item_light.lastActivityTime) {
        return this.item_light.lastActivityTime.split(" ");
      } else return "Время не известно";
    },
    newStr: function () {
      if (
        this.time[0] === "0" &&
        this.time[1] === "00" &&
        this.time[2] === "00"
      ) {
        return this.time[3] + "с " + "назад";
      } else if (
        this.time[0] === "0" &&
        this.time[1] === "00" &&
        this.time[2] === "00" &&
        this.time[3] === "00"
      ) {
        return "Обновлено сейчас";
      } else if (this.time[0] === "0" && this.time[1] === "00") {
        return this.time[2] + "м " + this.time[3] + "с " + "назад";
      } else if (this.time[0] === "0") {
        return (
          this.time[1] +
          "ч " +
          this.time[2] +
          "м " +
          this.time[3] +
          "с " +
          "назад"
        );
      } else
        return (
          this.time[0] +
          "д " +
          this.time[1] +
          "ч " +
          this.time[2] +
          "м " +
          this.time[3] +
          "с " +
          "назад"
        );
    },
  },
  methods: {
    handleClick() {
      this.modalInstance.close();
      // if (this.closeAfterTimeElapsed) {
      //   setTimeout(() => {
      //     this.modalInstance.close();
      //   }, this.seconds * 1000);
      // }
    },
  },
  mounted() {
    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);
    const elems = document.querySelectorAll("input[type=range]");
    M.Range.init(elems);

    const select = document.querySelector("select");
    M.FormSelect.init(select);

    M.updateTextFields();
    //  M.Modal.init();
    //  M.Modal.AutoInit();
  },
};
</script>
<style scoped>
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.font {
  font-weight: 500;
}
</style>
