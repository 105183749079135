<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalAllTimers" class="modal">
      <form class="modal__size" id="modalTimer">
        <div class="modal-content">
          <div class="modal-info__title-block mint-green-color">
            <p class="modal-info__title">
              Настройки
              <!-- <a class="icon-close" @click="closeModal()"
              ><i class="material-icons">close</i></a
            > -->
            </p>
            <div class="modal-info__close modal-close">
              <i class="material-icons white-text"> close</i>
            </div>
          </div>
          <div class="modal-scroll">
            <div class="modal-timer__wrap">
              <p class="modal-title">Время работы таймера:</p>
              <p
                class="error-text"
                v-if="
                  isValidate &&
                  !this.endTimeOff.length &&
                  !this.startTimeOn.length &&
                  !this.interval.length
                "
              >
                Поля не заполнены*
              </p>
              <div class="modal-timer__block-time">
                <div class="modal-timer__wrap-input">
                  <p
                    :class="
                      isValidate && !this.startTimeOn.length ? 'deduction' : ''
                    "
                  >
                    Включение
                  </p>
                  <div class="modal-timer__wrap-input-svg">
                    <svg viewBox="0 0 64 64" class="modal-timer__svg">
                      <g data-name="katman 2">
                        <path
                          fill="none"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M23 51v-4.56M43.87 40.83A7.26 7.26 0 0041 46.44"
                        />
                        <path
                          fill="#ecf0f1"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M35 54.46V57a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2.54a4 4 0 0 0 2 .54h2a4 4 0 0 0 2-.54z"
                        />
                        <path
                          fill="#ecf0f1"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M37 47v4a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4v-4z"
                        />
                        <path
                          fill="#f1c40f"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M47 25.82a15 15 0 0 1-5.63 11.89A11.24 11.24 0 0 0 37 46.44V47H27v-.56a10.65 10.65 0 0 0-4.08-8.5A15 15 0 1 1 47 25.82z"
                        />
                        <path
                          fill="none"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M30 47V35M34 47V35M30 35l-6-6M34 35l6-6M26 15.61A11.94 11.94 0 0132 14M20 26a12 12 0 012.57-7.42M32 8V4M19.27 13.27l-2.83-2.83M14 26h-4M50 26h4M44.73 13.27l2.83-2.83M27 50h10"
                        />
                        <path fill="none" d="M0 0h64v64H0z" />
                      </g>
                    </svg>
                    <!-- v-model="this.item_light.startTime" -->
                    <vue-timepicker
                      format="HH:mm:ss"
                      v-model="startTimeOn"
                      name="startTimeOn"
                    ></vue-timepicker>
                  </div>
                </div>
                <div class="modal-timer__wrap-input">
                  <p
                    :class="
                      isValidate && !this.endTimeOff.length ? 'deduction' : ''
                    "
                  >
                    Выключение
                  </p>
                  <div class="modal-timer__wrap-input-svg">
                    <svg viewBox="0 0 64 64" class="modal-timer__svg">
                      <g data-name="katman 2">
                        <path
                          fill="none"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M23 51v-4.56M43.87 40.83A7.26 7.26 0 0041 46.44M35 54.46V57a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2.54a4 4 0 002 .54h2a4 4 0 002-.54z"
                        />
                        <path
                          fill="none"
                          stroke="#434a54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M37 47v4a4 4 0 01-4 4h-2a4 4 0 01-4-4v-4zM47 25.82a15 15 0 01-5.63 11.89A11.24 11.24 0 0037 46.44V47H27v-.56a10.65 10.65 0 00-4.08-8.5A15 15 0 1147 25.82zM30 47V35M34 47V35M30 35l-6-6M34 35l6-6M26 15.61A11.94 11.94 0 0132 14M20 26a12 12 0 012.57-7.42M32"
                        />
                        <path fill="none" d="M0 0h64v64H0z" />
                      </g>
                    </svg>
                    <vue-timepicker
                      format="HH:mm:ss"
                      v-model="endTimeOff"
                      name="endTimeOff"
                    ></vue-timepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-timer__wrap">
              <p class="modal-title">Ручное управление таймерами:</p>
              <div class="modal-timer__btn-all">
                <button
                  class="btn waves-effect waves-light green"
                  style="color: white !important"
                  type="button"
                  :disabled="isDisabledSwitch"
                  name="action"
                  @click="postTimersAllOn()"
                >
                  Включить все
                  <i class="material-icons right">power_settings_new</i>
                </button>
                <button
                  class="btn waves-effect waves-light red"
                  style="color: white !important"
                  type="button"
                  :disabled="isDisabledSwitch"
                  name="action"
                  @click="postTimersAllOff()"
                >
                  Выключить все
                  <i class="material-icons right">power_settings_new</i>
                </button>
              </div>
            </div>
            <div class="modal-timer__wrap">
              <p class="modal-title">Задать интервал:</p>
              <div class="input-field col s6">
                <input
                  id="last_name"
                  type="number"
                  v-model="interval"
                  class="validate"
                  :class="
                    isValidate && !this.interval.length ? 'error-input' : ''
                  "
                  placeholder="1-100"
                />
                <!-- <label for="last_name">Интервал</label> -->
                <span
                  class="helper-text"
                  data-error="Заполните поле"
                  data-success="Заполнено"
                  >Впишите интервал</span
                >
              </div>
            </div>
          </div>
          <div class="timer-btn">
            <button
              type="button"
              @click="onSubmit()"
              name="btn_login"
              :class="!this.isValidate ? 'modal-close' : ''"
              class="col s12 btn btn-large waves-effect mint-green-color"
            >
              Задать настройки
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import VueTimepicker from "vue2-timepicker";
// import { Datetime } from "vue-datetime";
import { Settings } from "luxon";
import { actionTypes } from "@/store/modules/lights";
import { mapState } from "vuex";
// import Timer from "./Timer.vue";

Settings.defaultLocale = "rus";
// import { actionTypes } from "@/store/modules/lights";
export default {
  name: "AppModalAllTimers",
  data() {
    return {
      modalInstance: null,
      isValidate: false,
      startTimeOn: "",
      endTimeOff: "",
      interval: "",
      isDisabledSwitch: false,
      arrayCheckImei: [],
      arraySettings: {},
    };
  },
  components: {
    VueTimepicker,
    // Timer,
    // Datetime,
  },

  computed: {
    ...mapState({
      copyCoordinate: (state) => state.lights.copyCoordinate,
      messageStatus: (state) => state.socket.message,
      checkedTimer: (state) => state.lights.checkedTimer,
    }),
    getNewStatus: function () {
      if (this.messageStatus === null) {
        return false;
      } else if (this.messageStatus.status !== this.statusNow) {
        return true;
      } else {
        return false;
      }
    },
    offDisable: function () {
      if (this.isDisabledSwitch === false && this.getNewStatus) {
        return false;
      } else if (this.isDisabledSwitch && this.getNewStatus) {
        return false;
      } else if (this.isDisabledSwitch === false) {
        return false;
      } else return true;
    },
  },

  methods: {
    closeModal() {
      // this.modalInstance.close();
    },

    onSubmit() {
      this.isValidate = false;
      console.log(this.isValidate, "start submit");
      if (
        this.interval.length &&
        this.startTimeOn.length &&
        this.endTimeOff.length
      ) {
        this.checkedTimer.map((item) => {
          this.arrayCheckImei.push(item.imei);
        });
        this.arraySettings = {
          imei: this.arrayCheckImei,
          startTimeOn: this.startTimeOn,
          endTimeOff: this.endTimeOff,
          interval: this.interval,
        };
        this.$store
          .dispatch(actionTypes.settingsSelectedTimers, this.arraySettings)
          .then((resp) => {
            console.log(resp);
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      } else {
        this.isValidate = !this.isValidate;
        console.log(this.isValidate, "ПОЛЯ НЕ ЗАПОЛНЕнЫ");
      }
    },
    pasteCoordinate() {
      this.isCopyCoordinate = !this.isCopyCoordinate;
      console.log("paste", this.copyCoordinate);
    },
    onChangeData() {
      console.log("измениляся");
    },
    postTimersAllOff() {
      this.checkedTimer.map((item) => {
        this.arrayCheckImei.push(item.imei);
      });
      this.$store
        .dispatch(actionTypes.postTimersAllOff, this.arrayCheckImei)
        .then((resp) => {
          this.isDisabledSwitch = true;
          console.log(resp);
          this.arrayCheckImei = [];
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
      setTimeout(() => {
        this.isDisabledSwitch = false;
      }, 120000);
    },
    postTimersAllOn() {
      this.checkedTimer.map((item) => {
        this.arrayCheckImei.push(item.imei);
      });
      this.$store
        .dispatch(actionTypes.postTimersAllOn, this.arrayCheckImei)
        .then((resp) => {
          this.isDisabledSwitch = true;
          this.arrayCheckImei = [];
          console.log(resp);
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
      setTimeout(() => {
        this.isDisabledSwitch = false;
      }, 120000);
    },
    changeStatusTimer() {
      this.statusNow = this.item_light.status;
      // console.log("Изменился статус", this.statusNew);
      if (this.item_light.status === true) {
        this.$store
          .dispatch(actionTypes.postTimerOff, {
            imei: this.item_light.imei,
          })
          .then((resp) => {
            this.isDisabledSwitch = true;
            console.log(resp);
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      } else {
        console.log("Выключить");
        this.$store
          .dispatch(actionTypes.postTimerOn, {
            imei: this.item_light.imei,
          })
          .then((resp) => {
            this.isDisabledSwitch = true;
            console.log(resp);
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
      setTimeout(() => {
        this.isDisabledSwitch = false;
      }, 60000);
    },
  },
  mounted() {
    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);

    M.updateTextFields();
    //  M.Modal.init();
    //  M.Modal.AutoInit();
  },
};
</script>
<style scoped>
.modal {
  width: 35% !important;
}
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.timer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
