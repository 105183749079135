import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth";
// import socket from "@/store/modules/socket";
import lights from "@/store/modules/lights";
import history from "@/store/modules/history";
import demir from "@/store/modules/demir";
import socket from "@/store/modules/socket";
// import {
//   SOCKET_ONOPEN,
//   SOCKET_ONCLOSE,
//   SOCKET_ONERROR,
//   SOCKET_ONMESSAGE,
//   SOCKET_RECONNECT,
//   SOCKET_RECONNECT_ERROR,
// } from "@/store/modules/mutation-types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // socket: {
    //   isConnected: false,
    //   message: null,
    //   reconnectError: false,
    // },
  },

  mutations: {
    // [SOCKET_ONOPEN](state, event) {
    //   state.socket.isConnected = true;
    //   console.log(event, "event SOCKET_ONOPEN");
    // },
    // [SOCKET_ONCLOSE](state, event) {
    //   state.socket.isConnected = false;
    //   console.log(event, "event SOCKET_ONCLOSE");
    // },
    // [SOCKET_ONERROR](state, event) {
    //   console.error(state, event);
    // },
    // default handler called for all methods
    // [SOCKET_ONMESSAGE](state, message) {
    //   state.socket.message = message;
    // let newElem = lights.state.lights.find((element) => {
    //   return element.id === message.id;
    // });
    // console.log(newElem, "Найденный элемент");
    // var indexNewElem = lights.state.lights.indexOf(newElem);
    // if (message.status !== null) {
    //   lights.state.lights[indexNewElem].status = message.status;
    //   console.log(`Статус изменен `);
    // }
    // if (message.securityDoor !== null) {
    //   lights.state.lights[indexNewElem].securityDoor = message.securityDoor;
    //   console.log(`Статус дверей изменен `);
    // }
    // if (message.lastActivityTime) {
    //   lights.state.lights[indexNewElem].lastActivityTime =
    //     message.lastActivityTime;
    //   console.log(`Изменено время активности изменен `);
    // }
    // if (message.coordinate !== null) {
    //   lights.state.lights[indexNewElem].coordinate = message.coordinate;
    //   console.log(`Изменены координаты `);
    // }
    // },
    // // mutations for reconnect methods
    // [SOCKET_RECONNECT](state, count) {
    //   console.info(state, count);
    // },
    // [SOCKET_RECONNECT_ERROR](state) {
    //   state.socket.reconnectError = true;
    // },
  },
  actions: {},
  modules: {
    auth,
    lights,
    history,
    demir,
    socket,
  },
});
