<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalSettingsDemmir" class="modal">
      <form @submit.prevent="putPower" id="#modalSettings">
        <div class="modal-content">
          <div class="modal-info__title-block mint-green-color">
            <p class="modal-info__title">Настройка блока демирования</p>
            <div class="modal-info__close modal-close">
              <i class="material-icons white-text"> close</i>
            </div>
          </div>

          <div class="modal-scroll" v-if="property !== null">
            <SettingsDemir
              v-for="(div, index) in divs"
              :key="div.id"
              v-bind:item_settings="div"
              v-bind:property="property"
              v-on:remove="deleteRow(index)"
            />

            <div class="modal-settings__button-add">
              <button
                type="button"
                name="btn_login"
                @click="addRow()"
                class="col s12 btn btn-large waves-effect mint-green-color font"
              >
                Добавить таймер
              </button>
            </div>

            <div class="modal-timer__wrap">
              <p class="modal-title">Изменить интервал:</p>
              <div class="input-field col s6">
                <input
                  id="last_name"
                  type="number"
                  name="intervalUpdateRadioSignal"
                  class="validate"
                  :value="property.intervalUpdateRadioSignal"
                  placeholder="1-100"
                />
                <!-- <label for="last_name">Интервал</label> -->
                <span
                  class="helper-text"
                  data-error="Заполните поле"
                  data-success="Заполнено"
                  >Впишите интервал</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            name="btn_login"
            class="modal-close col s12 btn btn-large waves-effect mint-green-color"
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import { actionTypesDemir } from "@/store/modules/demir";
// import VueTimepicker from "vue2-timepicker";
import { mapState } from "vuex";
import SettingsDemir from "./SettingsDemir.vue";
// import SettingsDemir from "./SettingsDemir.vue";
export default {
  name: "AppModalSettingsPower",
  data() {
    return {
      modalInstance: null,
      power: 1,
      putData: {},
      count: 1,
      index: 1,
      divs: [],
      isCountMore: false,
    };
  },

  components: {
    // VueTimepicker,
    SettingsDemir,
  },
  computed: {
    ...mapState({
      property: (state) => state.demir.property,
    }),
  },

  methods: {
    addRow() {
      if (this.divs.length < 6) {
        this.isCountMore = false;
        this.divs.push({
          id: this.index,
          nameClockTitle: "Будильник № " + this.index,
          namePower: "alarmClockPower" + this.index,
          nameClock: "alarmClockValue" + this.index,
        });
        this.index++;
      } else {
        console.log("Добавлено уже более 6");
        this.isCountMore = true;
      }
    },
    deleteRow(index) {
      this.index--;
      for (var i = 0; i < this.divs.length; i++) {
        if (this.divs[i].id > this.divs[index].id) {
          this.divs[i].id = this.divs[i].id - 1;
          this.divs[i].nameClock = "alarmClockValue" + this.divs[i].id;
          this.divs[i].namePower = "alarmClockPower" + this.divs[i].id;
          (this.divs[i].nameClockTitle = "Будильник № " + this.divs[i].id),
            console.log(this.divs[i]);
        }
      }
      this.divs.splice(index, 1);
    },
    handleClick() {
      this.modalInstance.close();
    },
    putPower() {
      const formData = new FormData(document.getElementById("#modalSettings"));
      this.putData = [];
      for (let [name, value] of formData) {
        this.putData = { ...this.putData, [name]: value };
      }
      this.putData = {
        ...this.putData,
        id: this.property.id,
        imei: this.property.imei,
        streetNumber: this.property.streetNumber,
        lanternNumber: this.property.lanternNumber,
      };

      this.$store
        .dispatch(actionTypesDemir.postSetSettings, this.putData)
        .then((resp) => {
          console.log(resp);
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    addRowMount() {
      this.divs = [];
      this.index = 1;
      if (
        this.property.alarmClockValue1 !== "00:00:00" ||
        this.property.alarmClockPower1 !== 0
      ) {
        this.divs.push({
          id: this.index,
          nameClockTitle: "Будильник № " + this.index,
          namePower: "alarmClockPower" + this.index,
          nameClock: "alarmClockValue" + this.index,
        });
        this.index++;
      } else {
        this.divs.push({
          id: this.index,
          nameClockTitle: "Будильник № " + this.index,
          namePower: "alarmClockPower" + this.index,
          nameClock: "alarmClockValue" + this.index,
        });
        this.index++;
      }

      if (
        this.property.alarmClockValue2 !== "00:00:00" ||
        this.property.alarmClockPower2 !== 0
      ) {
        this.divs.push({
          id: this.index,
          nameClockTitle: "Будильник № " + this.index,
          namePower: "alarmClockPower" + this.index,
          nameClock: "alarmClockValue" + this.index,
        });
        this.index++;
      }
      if (
        this.property.alarmClockValue3 !== "00:00:00" ||
        this.property.alarmClockPower3 !== 0
      ) {
        this.divs.push({
          id: this.index,
          nameClockTitle: "Будильник № " + this.index,
          namePower: "alarmClockPower" + this.index,
          nameClock: "alarmClockValue" + this.index,
        });
        this.index++;
      }
      if (
        this.property.alarmClockValue4 !== "00:00:00" ||
        this.property.alarmClockPower4 !== 0
      ) {
        this.divs.push({
          id: this.index,
          nameClockTitle: "Будильник № " + this.index,
          namePower: "alarmClockPower" + this.index,
          nameClock: "alarmClockValue" + this.index,
        });
        this.index++;
      }
      if (
        this.property.alarmClockValue5 !== "00:00:00" ||
        this.property.alarmClockPower5 !== 0
      ) {
        this.divs.push({
          id: this.index,
          nameClockTitle: "Будильник № " + this.index,
          namePower: "alarmClockPower" + this.index,
          nameClock: "alarmClockValue" + this.index,
        });
        this.index++;
      }
      if (
        this.property.alarmClockValue6 !== "00:00:00" ||
        this.property.alarmClockPower6 !== 0
      ) {
        this.divs.push({
          id: this.index,
          nameClockTitle: "Будильник № " + this.index,
          namePower: "alarmClockPower" + this.index,
          nameClock: "alarmClockValue" + this.index,
        });
        this.index++;
      }
      console.log(this.divs, "divs");
    },
  },

  mounted() {
    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);

    this.$nextTick(function () {
      var elems = document.querySelectorAll("input[type=range]");
      M.Range.init(elems);
    });

    M.updateTextFields();

    //  M.Modal.init();
    //  M.Modal.AutoInit();
  },
};
</script>
<style scoped>
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}

input[type="range"] + .thumb.active .value {
  width: 100px !important;
  color: blue !important;
}
/***** These are to edit the thumb and the text inside the thumb *****/
input[type="range"] + .thumb {
  background-color: #dedede;
}
</style>
