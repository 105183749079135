import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/style.scss";
import "materialize-css/dist/css/materialize.min.css";
import "material-design-icons/iconfont/material-icons.css";
import "materialize-css";
import "leaflet/dist/leaflet.css";
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
// import "vue-datetime/dist/vue-datetime.css";
import "vue2-timepicker/dist/VueTimepicker.css";
// import VueWebsocket from "vue-native-websocket";
// import {
//   SOCKET_ONOPEN,
//   SOCKET_ONCLOSE,
//   SOCKET_ONERROR,
//   SOCKET_ONMESSAGE,
//   SOCKET_RECONNECT,
//   SOCKET_RECONNECT_ERROR,
// } from "./store/modules/mutation-types";

Vue.use(Datetime);

// const server = "ws://109.111.185.150:9030/ws";

// const mutations = {
//   SOCKET_ONOPEN,
//   SOCKET_ONCLOSE,
//   SOCKET_ONERROR,
//   SOCKET_ONMESSAGE,
//   SOCKET_RECONNECT,
//   SOCKET_RECONNECT_ERROR,
// };
// const configuration = {
//   store: store,
//   mutations: mutations,
//   format: "json",
//   reconnection: true, // (Boolean) whether to reconnect automatically (false)
//   reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
//   reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
// };

// Vue.use(VueWebsocket, server, configuration);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
