import lightsAPi from "@/api/lights.js";
// import { setItem } from "@/helpers/persistanceStorage";
// import axios from "axios";
// import router from "@/router";

const state = {
  isSubmitting: false,
  isLoading: false, //ожидание запроса
  lights: [],
  history: [],
  historyTimer: [],
  newItemlight: {},
  copyCoordinate: null,
  validationErrors: null, //ошибки
  checkedTimer: [],
  responseAdd: null,
};

export const mutationTypes = {
  getListStart: "[lights] getListStart",
  getListSuccess: "[lights] getListSuccess",
  getListFailure: "[lights] getListFailure",

  updateItemListStart: "[lights] updateItemListStart",
  updateItemListSuccess: "[lights] updateItemListSuccess",
  updateItemListFailure: "[lights] updateItemListFailure",

  getHistoryStart: "[lights] getHistoryStart",
  getHistorySuccess: "[lights]getHistorySuccess",
  getHistoryFailure: "[lights] getHistoryFailure",

  getHistoryTimerStart: "[lights] getHistoryTimerStart",
  getHistoryTimerSuccess: "[lights]getHistoryTimerSuccess",
  getHistoryTimerFailure: "[lights] getHistoryTimerFailure",

  postPowerStart: "[lights] postPoweSrtart",
  postPowerSuccess: "[lights]postPowerSuccess",
  postPowerFailure: "[lights] postPowerFailure",

  postTimerStart: "[lights]  postTimerStart",
  postTimerSuccess: "[lights] postTimerSuccess",
  postTimerFailure: "[lights]  postTimerFailure",

  //Включение таймера(new version)
  postTimerOnStart: "[timer] postTimerOnStart",
  postTimerOnSuccess: "[timer] postTimerOnSuccess",
  postTimerOnFailure: "[timer]  postTimerOnFailure",

  //Выключение таймера(new version)
  postTimerOffStart: "[timer] postTimerOffStart",
  postTimerOffSuccess: "[timer] postTimerOffSuccess",
  postTimerOffFailure: "[timer]  postTimerOffFailure",

  //Включение выбранных таймеров(new version)
  postTimersAllOnStart: "[timer] postTimersAllOnStart",
  postTimersAllOnSuccess: "[timer] postTimersAllOnSuccess",
  postTimersAllOnFailure: "[timer]  postTimersAllOnFailure",

  //Выключение выбранных таймеров(new version)
  postTimersAllOffStart: "[timer] postTimersAllOffStart",
  postTimersAllOffSuccess: "[timer] postTimersAllOffSuccess",
  postTimersAllOffFailure: "[timer]  postTimersAllOffFailure",

  //Изменение имени таймера(new version)
  editNameTimerStart: "[timer] editNameTimerStart",
  editNameTimerSuccess: "[timer] editNameTimerSuccess",
  editNameTimerFailure: "[timer]  editNameTimerFailure",

  //Получение одного измененного объекта
  getNewTimerStart: "[timer] getNewTimerStart",
  getNewTimerSuccess: "[timer] getNewTimerSuccess",
  getNewTimerFailure: "[timer]  getNewTimerFailure",

  //Настройки таймера
  settingsTimerStart: "[timer] settingsTimerStart",
  settingsTimerSuccess: "[timer] settingsTimerSuccess",
  settingsTimerFailure: "[timer]  settingsTimerFailure",

  //Настройки выбранных таймеров
  settingsSelectedTimersStart: "[timer]  settingsSelectedTimersStart",
  settingsSelectedTimersSuccess: "[timer]  settingsSelectedTimersSuccess",
  settingsSelectedTimersFailure: "[timer]   settingsSelectedTimersFailure",

  //Добавление нового блока

  addTimerStart: "[timer]  addTimerStart",
  addTimerSuccess: "[timer]  addTimerSuccess",
  addTimerFailure: "[timer]   addTimerFailure",

  //Удаление блока таймера

  deleteTimerStart: "[timer]  deleteTimerStart",
  deleteTimerSuccess: "[timer]  deleteTimerSuccess",
  deleteTimerFailure: "[timer]   deleteTimerFailure",

  //Изменение координат блока
  changeCoordinateBlockStart: "[timer]  changeCoordinateBlockStart",
  changeCoordinateBlockSuccess: "[timer] changeCoordinateBlockSuccess",
  changeCoordinateBlockFailure: "[timer]   changeCoordinateBlockFailure",
  //Копирование координат
  copyCoordinateSuccess: "[lights] copyCoordinateSuccess",

  checkedTimerSuccess: "[timer] checkedTimerSuccess",
};
export const actionTypes = {
  getList: "[lights] getList",
  getHistory: "[lights] getHistory",
  postPower: "[lights] postPower",
  updateItemList: "[lights] updateItemList",
  postTimer: "[lights] postTimer",
  getHistoryTimer: "[lights] getHistoryTimer",
  copyCoordinate: " [lights] copyCoordinate",
  postTimerOff: "[lights] postTimerOff",
  postTimerOn: "[lights] postTimerOn",
  checkedTimer: "[timer] checkedTimer",
  editNameTimer: "[timer] editNameTimer",
  getNewTimer: "[timer] getNewTimer",
  settingsTimer: "[timer] settingsTimer",
  postTimersAllOff: "[timer] postTimersAllOff",
  postTimersAllOn: "[timer] postTimersAllOn",
  settingsSelectedTimers: "[timer] settingsSelectedTimers",
  changeCoordinateBlock: "[timer] changeCoordinateBlock",
  addTimer: "[timer] addTimer",
  deleteTimer: "[timer] deleteTimer",
  // /chenge/coordinate
  //   exitLogin: "[auth] exit",
};

const mutations = {
  //Получение блоков
  [mutationTypes.getListStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getListSuccess](state, items) {
    state.isLoading = false;
    state.lights = items;
  },
  [mutationTypes.getListFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Обновление информации блока
  [mutationTypes.updateItemListStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.updateItemListSuccess](state, items) {
    state.isLoading = false;
    state.newItemlight = items;
  },
  [mutationTypes.updateItemListFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Получение истории
  [mutationTypes.getHistoryStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getHistorySuccess](state, items) {
    state.isLoading = false;
    state.history = items;
  },
  [mutationTypes.getHistoryFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Получение истории таймера
  [mutationTypes.getHistoryTimerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getHistoryTimerSuccess](state, items) {
    state.isLoading = false;
    state.historyTimer = items;
  },
  [mutationTypes.getHistoryTimerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Отправление мощности
  [mutationTypes.postPowerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.postPowerSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postPowerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Установка таймера
  [mutationTypes.postTimerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.postTimerSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postTimerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Включение таймера(new!)
  [mutationTypes.postTimerOnStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.postTimerOnSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postTimerOnFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Выключение таймера(new!)
  [mutationTypes.postTimerOffStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.postTimerOffSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postTimerOffFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

  //Включение выбранных таймеров(new!)
  [mutationTypes.postTimersAllOnStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.postTimersAllOnSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postTimersAllOnFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Выключение выбранных таймеров(new!)
  [mutationTypes.postTimersAllOffStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.postTimersAllOffSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postTimersAllOffFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Изменение имени таймера(new!)
  [mutationTypes.editNameTimerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.editNameTimerSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.editNameTimerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Изменение координат блока

  [mutationTypes.changeCoordinateBlockStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeCoordinateBlockSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.changeCoordinateBlockFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Получение одного измененного объекта
  [mutationTypes.getNewTimerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  //замена элементов
  [mutationTypes.getNewTimerSuccess](state, payload) {
    state.isLoading = false;
    let newElem = state.lights.find((element) => {
      return element.id === payload.id;
    });
    var indexNewElem = state.lights.indexOf(newElem);
    state.lights.splice(indexNewElem, 1, payload);
  },
  [mutationTypes.getNewTimerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Настройки таймера
  [mutationTypes.settingsTimerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.settingsTimerSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.settingsTimerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Настроки выбранных таймеров
  [mutationTypes.settingsSelectedTimersStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.settingsSelectedTimersSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.settingsSelectedTimersFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Добавление нового таймера

  [mutationTypes.addTimerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.addTimerSuccess](state, response) {
    state.isLoading = false;
    state.responseAdd = response.data;
  },
  [mutationTypes.addTimerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

  //Удаление блока таймера

  [mutationTypes.deleteTimerStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteTimerSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteTimerFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Сохранение координат
  [mutationTypes.copyCoordinateSuccess](state, payload) {
    state.copyCoordinate = payload;
  },
  //отображение на карте таймеров
  [mutationTypes.checkedTimerSuccess](state, payload) {
    state.checkedTimer = payload;
  },
};
const actions = {
  [actionTypes.getList](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getListStart);
      lightsAPi
        .getList()
        .then((response) => {
          // if (response.data.status === "SUCCESS") {
          context.commit(mutationTypes.getListSuccess, response.data);
          // } else {
          //   context.commit(mutationTypes.getListFailure, response.data);
          // }
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.updateItemList](context, credentials) {
    console.log(credentials);
    return new Promise((resolve) => {
      context.commit(mutationTypes.updateItemListStart);
      lightsAPi
        .updateItemList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.updateItemListSuccess,
              response.data.result
            );
          } else {
            context.commit(mutationTypes.updateItemListFailure, response.data);
          }
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.getHistory](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getHistoryStart);
      lightsAPi
        .getHistory(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getHistorySuccess,
              response.data.result
            );
          } else {
            context.commit(mutationTypes.getHistoryFailure, response.data);
          }
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.getHistoryTimer](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getHistoryTimerStart);
      lightsAPi
        .getHistoryTimer(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getHistoryTimerSuccess,
              response.data.result
            );
          } else {
            context.commit(mutationTypes.getHistoryTimerFailure, response.data);
          }
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.postPower](context, credentials) {
    return new Promise((resolve) => {
      console.log(credentials);
      context.commit(mutationTypes.postPowerStart);
      lightsAPi
        .postPower(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.postPowerSuccess);
          } else {
            context.commit(mutationTypes.postPowerFailure, response.data);
          }
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.postTimer](context, credentials) {
    return new Promise((resolve) => {
      console.log(credentials);
      context.commit(mutationTypes.postTimerStart);
      lightsAPi
        .postTimer(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.postTimerSuccess);
          } else {
            context.commit(mutationTypes.postTimerFailure, response.data);
          }
          resolve(response.data);
        })
        .catch((response) => {
          console.log("ошибка", response);
        });
    });
  },
  //_____________________________________________________________________________________
  [actionTypes.postTimerOn](context, credentials) {
    return new Promise((resolve) => {
      console.log(credentials);
      context.commit(mutationTypes.postTimerOnStart);
      lightsAPi
        .postTimerOn(credentials)
        .then((response) => {
          context.commit(mutationTypes.postTimerOnSuccess);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.postTimerOnFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.postTimerOff](context, credentials) {
    return new Promise((resolve) => {
      console.log(credentials);
      context.commit(mutationTypes.postTimerOffStart);
      lightsAPi
        .postTimerOff(credentials)
        .then((response) => {
          context.commit(mutationTypes.postTimerOffSuccess);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.postTimerOffFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.postTimersAllOff](context, credentials) {
    return new Promise((resolve) => {
      console.log(credentials);
      context.commit(mutationTypes.postTimersAllOffStart);
      lightsAPi
        .postTimersAllOff(credentials)
        .then((response) => {
          context.commit(mutationTypes.postTimersAllOffSuccess);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.postTimersAllOffFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.postTimersAllOn](context, credentials) {
    return new Promise((resolve) => {
      console.log(credentials);
      context.commit(mutationTypes.postTimersAllOnStart);
      lightsAPi
        .postTimersAllOn(credentials)
        .then((response) => {
          context.commit(mutationTypes.postTimersAllOnSuccess);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.postTimersAllOnFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },

  [actionTypes.editNameTimer](context, credentials) {
    return new Promise((resolve) => {
      // console.log(credentials);
      context.commit(mutationTypes.editNameTimerStart);
      lightsAPi
        .editNameTimer(credentials)
        .then((response) => {
          context.commit(mutationTypes.editNameTimerSuccess);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.editNameTimerFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.getNewTimer](context, credentials) {
    return new Promise((resolve) => {
      // console.log(credentials);
      context.commit(mutationTypes.getNewTimerStart);
      lightsAPi
        .getNewTimer(credentials)
        .then((response) => {
          context.commit(mutationTypes.getNewTimerSuccess, response.data);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.getNewTimerFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.settingsTimer](context, credentials) {
    return new Promise((resolve) => {
      // console.log(credentials);
      context.commit(mutationTypes.settingsTimerStart);
      lightsAPi
        .settingsTimer(credentials)
        .then((response) => {
          context.commit(mutationTypes.settingsTimerSuccess, response.data);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.settingsTimerFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.settingsSelectedTimers](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.settingsSelectedTimersStart);
      lightsAPi
        .settingsSelectedTimers(credentials)
        .then((response) => {
          context.commit(
            mutationTypes.settingsSelectedTimersSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(
            mutationTypes.settingsSelectedTimersFailure,
            response.data
          );
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.changeCoordinateBlock](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changeCoordinateBlockStart);
      lightsAPi
        .changeCoordinateBlock(credentials)
        .then((response) => {
          context.commit(mutationTypes.changeCoordinateBlockSuccess);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(
            mutationTypes.changeCoordinateBlockFailure,
            response.data
          );
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.addTimer](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addTimerStart);
      lightsAPi
        .addTimer(credentials)
        .then((response) => {
          context.commit(mutationTypes.addTimerSuccess, response);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.addTimerFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },
  [actionTypes.deleteTimer](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteTimerStart);
      lightsAPi
        .deleteTimer(id)
        .then((response) => {
          context.commit(mutationTypes.deleteTimerSuccess);
          resolve(response.data);
        })
        .catch((response) => {
          context.commit(mutationTypes.deleteTimerFailure, response.data);
          console.log("ошибка", response);
        });
    });
  },

  //_____________________________________________________________________________________
  [actionTypes.copyCoordinate](context, credentials) {
    context.commit(mutationTypes.copyCoordinateSuccess, credentials);
  },
  [actionTypes.checkedTimer](context, credentials) {
    context.commit(mutationTypes.checkedTimerSuccess, credentials);
  },
};
export default {
  state,
  mutations,
  actions,
};
