import axios from "@/api/axios";

const getList = () => {
  return axios.get("timer/all", { useCredentails: true });
};
const updateItemList = (credentials) => {
  return axios.post(
    "/api/1/refresh_unit" +
      "?unitId=" +
      credentials.unitId +
      "&type=" +
      credentials.type
    // {
    //   body: {},
    // }
  );
};
//Включение таймера
const postTimerOn = (credentials) => {
  return axios.post("timer/on" + "?imei=" + credentials.imei, {
    body: {},
  });
};
//Выключение таймера
const postTimerOff = (credentials) => {
  return axios.post("timer/off" + "?imei=" + credentials.imei, {
    body: {},
  });
};
//Выключение всех выбранных таймеров
const postTimersAllOff = (credentials) => {
  return axios.post("timer/off/list", credentials);
};
//Включение всех выбранных таймеров
const postTimersAllOn = (credentials) => {
  return axios.post("timer/on/list", credentials);
};

//Настройки таймера
const settingsTimer = (credentials) => {
  return axios.post("set/settings/timer", credentials);
};
//Настройки выбранных таймеров
const settingsSelectedTimers = (credentials) => {
  return axios.post("set/settings/timer/list", credentials);
};
const getHistory = (credentials) => {
  return axios.get("get_unit_history", {
    params: {
      unitId: credentials.unitId,
      type: credentials.type,
    },
  });
};

const getHistoryTimer = (credentials) => {
  return axios.get("get_timer_history", {
    params: {
      unitId: credentials.unitId,
      type: credentials.type,
    },
  });
};

const postPower = (credentials) => {
  return axios.post(
    "change_power" +
      "?unitId=" +
      credentials.unitId +
      "&type=" +
      credentials.type +
      "&power=" +
      credentials.power,
    {
      body: {},
    }
  );
};

const postTimer = (credentials) => {
  return axios.post("set/settings/timer", credentials);
};
//изменение имени таймера
const editNameTimer = (credentials) => {
  return axios.post("edit/name/timer", credentials);
};
//получение новых данных одного объекта
const getNewTimer = (credentials) => {
  return axios.get("get/timer" + "?timerId=" + credentials);
};
//изменение координат блока

const changeCoordinateBlock = (credentials) => {
  return axios.post("change/coordinate", credentials);
};

//добавление нового таймера

const addTimer = (info) => {
  return axios.post("add/timer/", info);
};

//Удаление таймера

const deleteTimer = (id) => {
  return axios.post(`/timer/delete/${id}`);
};
export default {
  getList,
  getHistory,
  postPower,
  postTimer,
  updateItemList,
  getHistoryTimer,
  postTimerOn,
  postTimerOff,
  editNameTimer,
  getNewTimer,
  settingsTimer,
  postTimersAllOff,
  postTimersAllOn,
  settingsSelectedTimers,
  changeCoordinateBlock,
  addTimer,
  deleteTimer,
};
