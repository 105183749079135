<template>
  <div class="index">
    <SideNav
      @itemIndex="itemIndex"
      @zoomToMarker="zoomToMarker"
      @clickSettings="clickSettings"
    />
    <MapVue2Leaflet @itemIndex="itemIndex" ref="mapView" />
    <ModalBlock @itemIndex="itemIndex" v-bind:item_light="itemLight" />
    <modal-block-power
      v-bind:item_light="itemLight"
      ref="modalPower"
    ></modal-block-power>
    <modal-block-history v-bind:item_light="itemLight"></modal-block-history>

    <modal-timer v-bind:item_light.sync="itemLight"></modal-timer>

    <modal-timer-history v-bind:item_light="itemLight"></modal-timer-history>
    <modal-change-coordinates
      v-bind:item_light="itemLight"
    ></modal-change-coordinates>
    <modal-all-timers></modal-all-timers>
    <modal-all-block-power></modal-all-block-power>
    <modal-info-dimer></modal-info-dimer>
    <modal-about-program></modal-about-program>
    <modal-add-block></modal-add-block>
    <modal-delete v-bind:item_light="itemLight"></modal-delete>
    <!-- <modal-block></modal-block> -->
  </div>
</template>

<script>
import ModalBlock from "../components/ModalBlock.vue";
import SideNav from "../components/SideNav.vue";
import M from "materialize-css";
import MapVue2Leaflet from "../components/MapVue2Leaflet.vue";
import { actionTypes } from "@/store/modules/lights";
// import { actionTypesSocket } from "@/store/modules/socket";
// import { actionTypesDemir } from "@/store/modules/demir";
import ModalBlockPower from "../components/ModalBlockPower.vue";
import ModalBlockHistory from "../components/ModalBlockHistory.vue";
import { mapState } from "vuex";
import ModalTimer from "../components/ModalTimer.vue";
import ModalTimerHistory from "../components/ModalTimerHistory.vue";
import ModalChangeCoordinates from "../components/ModalChangeCoordinates.vue";
import ModalAllTimers from "../components/ModalAllTimers.vue";
import ModalInfoDimer from "../components/ModalInfoDimer.vue";
import ModalAllBlockPower from "../components/ModalAllBlockPower.vue";
import ModalAboutProgram from "../components/ModalAboutProgram.vue";
import ModalAddBlock from "../components/ModalAddBlock";
import ModalDelete from "../components/ModalDelete.vue";
import { actionTypesSocket } from "@/store/modules/socket";
export default {
  name: "AppIndex",
  data() {
    return {
      itemLight: null,
      checkItem: [],
    };
  },
  components: {
    SideNav,
    MapVue2Leaflet,
    ModalBlock,
    ModalBlockPower,
    ModalBlockHistory,
    ModalTimer,
    ModalTimerHistory,
    ModalChangeCoordinates,
    ModalAllTimers,
    ModalInfoDimer,
    ModalAllBlockPower,
    ModalAboutProgram,
    ModalAddBlock,
    ModalDelete,
  },
  methods: {
    itemIndex(items) {
      // console.log(items, "Index SCREEN");
      this.itemLight = items;
    },
    clickSettings() {
      this.$refs.modalPower.addRowMount();
    },
    zoomToMarker(item) {
      // this.coordinates = item.coordinate;
      this.$refs.mapView.openPopUp(item.coordinate);
    },
  },
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      userInfo: (state) => state.auth.userInfo,
      isConnected: (state) => state.socket.isConnected,
    }),
  },

  mounted() {
    if (this.token === "undefined" || this.token === "") {
      this.$router.push({ name: "Login" });
    }
    M.AutoInit();
    if (this.userInfo.id && !this.isConnected) {
      this.$store.dispatch(actionTypesSocket.socketConnect, this.userInfo.id);
    }

    this.$store
      .dispatch(actionTypes.getList)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>
