<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalHistory" class="modal">
      <div class="modal-content">
        <div class="modal-info__title-block mint-green-color">
          <p class="modal-info__title">
            История
            <!-- <a class="icon-close" @click="closeModal()"
              ><i class="material-icons">close</i></a
            > -->
          </p>
          <div class="modal-info__close modal-close">
            <i class="material-icons white-text"> close</i>
          </div>
        </div>
        <div class="modal-scroll">
          <ul
            class="collection"
            v-for="(item, index) in this.historyAll"
            :key="index"
          >
            <li
              class="collection-item active"
              style="background: #f3f2c9; color: black; font-weight: 600"
            >
              {{ item.timeChange }}
            </li>
            <li class="collection-item message" style="font-weight: 500">
              Пользователь Иванов Иван {{ item.fio }}
              <p v-if="item.type === '1'">
                Выполнил включение таймера
                <i class="material-icons right icon-history">timer</i>
              </p>
              <p v-if="item.type === '2'">
                Выполнил выключение таймера
                <i class="material-icons right icon-history">timer_off</i>
              </p>
              <p v-if="item.type === '3'">
                Выполнил настройки таймера
                <i class="material-icons right icon-history">settings</i>
              </p>
              Kоманда:
              {{ item.currentState }}
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          name="btn_login"
          class="modal-close col s12 btn btn-large waves-effect mint-green-color"
        >
          ОК
        </button>
        <!-- <a href="#!" class="modal-close waves-effect waves-green btn-flat"
          >Изменить</a
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import M from "materialize-css";
export default {
  name: "AppModalBlockHistory",
  data() {
    return {
      modalInstance: null,
      power: 1,
    };
  },
  props: {
    item_light: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    checkStatus() {
      if (this.item_light.status === 1) {
        return "Включено";
      } else {
        return "Выключено";
      }
    },
    ...mapState({
      isLoading: (state) => state.lights.isSubmitting,
      history: (state) => state.lights.history,
      historyAll: (state) => state.history.historyAll,
    }),
  },
  methods: {
    handleClick() {
      this.modalInstance.close();
    },
  },
  mounted() {
    //  var elems = document.querySelectorAll(".modal");
    //  var instances = M.Modal.init(elems);

    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);

    const elems = document.querySelectorAll("input[type=range]");
    M.Range.init(elems);

    const select = document.querySelector("select");
    M.FormSelect.init(select);

    M.updateTextFields();

    //  M.Modal.init();
    //  M.Modal.AutoInit();
  },
};
</script>
<style scoped>
.modal {
  width: 35% !important;
}
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.font {
  font-weight: 500;
}
.message {
  font-size: 16px;
}
</style>
