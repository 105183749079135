<template>
  <div class="shema-block index-green-color z-depth-2 margin-sb">
    <div class="shema-block__title">
      <div class="shema-block__title-wrap">
        <!-- <p> -->
        <label class="shema-block__checkbox-wrap">
          <input
            type="checkbox"
            class="filled-in"
            :value="item_data"
            @change="onChange()"
            v-model="checked"
          />

          <span class=""></span>
        </label>
        <form v-if="this.isEditName === true" class="shema-block__form-edit">
          <input
            id="last_name"
            type="text"
            class="validate shema-block__input-edit"
            name="nameTimer"
            :value="this.item_data.nameTimer"
          />
        </form>
        <p
          v-if="this.isEditName === false && this.item_data.nameTimer"
          @click="zoomToMarker()"
          class="title-text"
        >
          {{ this.item_data.nameTimer }}
        </p>
        <p
          class="title-text-empty"
          v-else-if="this.isEditName === false"
          @click="zoomToMarker()"
        >
          Введите название таймера
        </p>
      </div>
      <!-- <div class="input-field"> -->
      <ul
        :id="'dropdown' + this.index"
        class="dropdown-content dropdown-content-update"
      >
        <li
          class="modal-trigger"
          data-target="modal1"
          style="display: flex; align-items: center"
        >
          <span class="size-text-14 grey-text text-darken-4"
            >Запрос состояния</span
          >
        </li>

        <hr />
        <li
          class="modal-trigger"
          data-target="modal4"
          style="display: flex; align-items: center"
        >
          <!-- <a class="">Настройки таймера</a> -->
          <span class="size-text-14 grey-text text-darken-4"
            >Настройки таймера</span
          >
        </li>
        <hr />
        <li
          class="modal-trigger"
          data-target="modalDelete"
          style="display: flex; align-items: center"
          @click="deleteClick"
        >
          <!-- <a class="">Настройки таймера</a> -->
          <span class="size-text-14 grey-text text-darken-4">Удалить</span>
        </li>
      </ul>
      <!-- </div> -->
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="edit"
        class="svg-inline--fa fa-edit fa-w-18 shema-block__svg-edit"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        v-if="this.isEditName === false"
        @click="editNameTimer"
      >
        <path
          fill="white"
          d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
        ></path>
      </svg>
      <svg
        viewBox="0 0 256 256"
        class="shema-block__svg-check"
        v-if="this.isEditName === true"
        @click="changeNameTimer()"
      >
        <rect width="25" height="25" fill="none" />
        <polyline
          fill="none"
          stroke="#a7ffeb"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
          points="216 72.005 104 184 48 128.005"
        />
      </svg>
      <!-- <a href=""> -->
      <i
        class="material-icons dropdown-trigger"
        style="color: white; cursor: pointer"
        :id="'drop' + this.index"
        :data-target="'dropdown' + this.index"
        @click="handleClick()"
        role="button"
        aria-label="Menu"
        >more_vert</i
      >
      <!-- </a> -->
    </div>
    <li><div class="divider"></div></li>
    <div
      class="shema-block__info waiting"
      v-if="this.item_data.activatedStatus === 1"
    >
      <p class="info-text">
        IMEI: <span class="add-text">{{ this.item_data.imei }}</span>
      </p>
      <p class="info-text">Ожидание ответа блока</p>
    </div>
    <div class="shema-block__info" v-bind:class="classObject" v-else>
      <p class="info-text" v-if="this.place">
        <!-- Адрес: -->
        <span class="add-text"
          >{{ this.place.road }} {{ this.place.house_number }}
          {{ this.place.city_district }}
        </span>
        <span class="add-text">{{ this.fullPlace }}</span>

        <!-- <span class="add-text">Адресс не определен</span> -->
      </p>

      <p class="info-text">
        Статус: <span class="add-text">{{ this.checkStatus }}</span>
      </p>
      <p class="info-text">
        Активность:
        <span class="add-text-success" v-bind:class="messageColor">{{
          newStr
        }}</span>
      </p>
      <div
        class="shema-block__btn-lock tooltipped"
        data-position="bottom"
        :data-tooltip="'Дверь закрыта'"
        v-if="this.item_data.securityDoor"
      >
        <a class="btn-floating green"
          ><i class="material-icons">lock_outline</i></a
        >
      </div>
      <div
        class="shema-block__btn-lock tooltipped"
        data-position="bottom"
        :data-tooltip="'Дверь открыта!'"
        v-else
      >
        <a class="btn-floating red lighten-1 pulse"
          ><i class="material-icons">lock_open</i></a
        >
      </div>
    </div>
    <!-- <ModalBlock /> -->
  </div>
</template>

<script>
import M from "materialize-css";
import axios from "axios";
import { actionTypes } from "@/store/modules/lights";
import { mapState } from "vuex";
// import ModalBlock from "./ModalBlock.vue";
export default {
  name: "AppItemList",
  data() {
    return {
      // modalInstance: null,
      modalTooltip: null,
      dropInstance: null,
      modalInstance: null,
      place: {},
      fullPlace: "",
      checkBlock: "",
      isEditName: false,
      checkedP: false,
      newNameTimer: [],
      loader: true,
      open: null,

      // status: "",
    };
  },
  props: {
    item_data: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return {};
      },
    },
    value: {
      type: Array,
      default() {
        return {};
      },
    },
  },

  methods: {
    handleClick(key) {
      console.log(this.item_data, "handleClick");
      this.$emit("itemData", this.item_data);
      const elems = document.getElementById("drop" + this.index);
      this.modalInstance = M.Dropdown.init(elems);
      // console.log(this.modalInstance.open);
      this.modalInstance.open(key);
      // const elems = document.getElementById("drop");
      // this.dropInstance = M.Dropdown.init(elems, open);
    },
    deleteClick() {
      const elems = document.getElementById("drop" + this.index);
      this.modalInstance = M.Dropdown.init(elems, close);
      // console.log(this.modalInstance.open);
      // this.modalInstance;
      //  console.log(this.item_data, "handleClick");
      // this.$emit("itemData", this.item_data);
      // const elems = document.getElementById("drop" + this.index);
      // this.modalInstance = M.Dropdown.init(elems, this.$destroy);
    },
    zoomToMarker() {
      console.log(this.item_data, "Click marker");
      this.$emit("zoomToMarker", this.item_data);
    },
    handleClickTimer() {
      console.log("handleClickTimer");
      this.$emit("itemDataHistory", this.item_data);
    },
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheck");
    },
    editNameTimer() {
      this.isEditName = !this.isEditName;
    },
    changeNameTimer() {
      this.isEditName = !this.isEditName;
      const forms = document.forms;
      const formData = new FormData(forms[1]);
      this.newNameTimer = [];
      for (let [name, value] of formData) {
        this.newNameTimer = { ...this.newNameTimer, [name]: value };
        // console.log(`${name}: ${value}`);
      }
      this.newNameTimer = { ...this.newNameTimer, id: this.item_data.id };
      this.$store
        .dispatch(actionTypes.editNameTimer, this.newNameTimer)
        .then(() => {
          this.$store.dispatch(actionTypes.getNewTimer, this.newNameTimer.id);
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },

  computed: {
    ...mapState({
      messageStatus: (state) => state.socket.message,
    }),
    checkStatus() {
      if (this.item_data.status === true) {
        return "Включено";
      } else {
        return "Отключено";
      }
    },
    classObject: function () {
      return {
        noactive: this.checkStatus === "Отключено",
      };
    },
    messageColor: function () {
      return {
        urgently: this.item_data.difference,
      };
    },
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.checkedP = val;
      },
    },
    time: function () {
      if (this.item_data.activatedStatus === 1) {
        return "Ожидание";
      } else {
        return this.item_data.lastActivityTime.split(" ");
      }
    },
    newStr: function () {
      if (this.item_data.activatedStatus === 1) {
        return "Ожидание";
      } else if (
        this.time[0] === "0" &&
        this.time[1] === "00" &&
        this.time[2] === "00"
      ) {
        return this.time[3] + "с " + "назад";
      } else if (
        this.time[0] === "0" &&
        this.time[1] === "00" &&
        this.time[2] === "00" &&
        this.time[3] === "00"
      ) {
        return "Обновлено сейчас";
      } else if (this.time[0] === "0" && this.time[1] === "00") {
        return this.time[2] + "м " + this.time[3] + "с " + "назад";
      } else if (this.time[0] === "0") {
        return (
          this.time[1] +
          "ч " +
          this.time[2] +
          "м " +
          this.time[3] +
          "с " +
          "назад"
        );
      } else
        return (
          this.time[0] +
          "д " +
          this.time[1] +
          "ч " +
          this.time[2] +
          "м " +
          this.time[3] +
          "с " +
          "назад"
        );
    },
  },
  mounted() {
    // const elems = document.getElementById("drop");
    // // const elems = document.querySelectorAll(".dropdown-trigger");
    // this.dropInstance = M.Dropdown.init(elems);
    // const elems = document.querySelectorAll(".dropdown-trigger");
    const elems = document.getElementById("drop" + this.index);
    this.modalInstance = M.Dropdown.init(elems);

    var elemsTool = document.querySelectorAll(".tooltipped");
    this.modalTooltip = M.Tooltip.init(elemsTool);

    this.searchCoord = {
      lat: this.item_data.coordinate[0],
      lon: this.item_data.coordinate[1],
    };
    axios
      .get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.searchCoord.lat}&lon=${this.searchCoord.lon}&zoom=18&addressdetails=1`
      )
      .then((response) => {
        this.loader = !this.loader;
        if (
          response.data.address.road ||
          response.data.address.city_district ||
          response.data.address.house_number
        ) {
          this.place = {
            road: response.data.address.road,
            city_district: response.data.address.city_district,
            house_number: response.data.address.house_number,
          };
        } else this.fullPlace = response.data.display_name;

        // this.place = response.data.display_name;
      })
      .catch(() => {
        this.loader = !this.loader;
        this.place = "Место не найдено";
      });
  },
};
</script>

<style scoped>
.dropdown-content li {
  padding: 5px 8px 5px 11px !important;
}
.noactive {
  background-color: #e0e0e0;
  /* ackground-color: #e0e0e0;  */
}
</style>
