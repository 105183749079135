<template>
  <div>
    <!-- Modal Structure -->
    <div id="modal5" class="modal">
      <div class="modal-content" v-if="this.item_light !== null">
        <h4 class="modal-info__title blue-grey darken-1">
          История {{ item_light.nameTimer }}
        </h4>
        <div class="modal-scroll">
          <ul
            class="collection"
            v-for="(item, index) in this.historyTimer"
            :key="index"
          >
            <li
              class="collection-item active"
              style="background: #8eacbb; font-weight: 500"
            >
              {{ item.changeTime }}
            </li>
            <li class="collection-item message" style="font-weight: 500">
              Пользователь {{ item.userName }} назначил таймер с
              {{ item.startActivityTime }} по {{ item.endActivityTime }}
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          name="btn_login"
          class="modal-close col s12 btn btn-large waves-effect blue-grey darken-1"
        >
          ОК
        </button>
        <!-- <a href="#!" class="modal-close waves-effect waves-green btn-flat"
          >Изменить</a
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import M from "materialize-css";
export default {
  name: "AppModalTimerHistory",
  data() {
    return {
      modalInstance: null,
      power: 1,
    };
  },
  props: {
    item_light: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    checkStatus() {
      if (this.item_light.status === 1) {
        return "Включено";
      } else {
        return "Выключено";
      }
    },

    ...mapState({
      isLoading: (state) => state.lights.isSubmitting,
      historyTimer: (state) => state.lights.historyTimer,
    }),
  },
  methods: {
    handleClick() {
      this.modalInstance.close();
    },
  },
  mounted() {
    //  var elems = document.querySelectorAll(".modal");
    //  var instances = M.Modal.init(elems);

    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);
    const elems = document.querySelectorAll("input[type=range]");
    M.Range.init(elems);

    const select = document.querySelector("select");
    M.FormSelect.init(select);

    M.updateTextFields();

    //  M.Modal.init();
    //  M.Modal.AutoInit();
  },
};
</script>
<style scoped>
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
.font {
  font-weight: 500;
}
.message {
  font-size: 16px;
}
</style>
