// import axios from "axios";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import lights from "./lights";
import demir from "./demir";
import auth from "./auth";
const state = {
  socket: new SockJS("https://timers.autosystem.su/ws"),
  // socket: new SockJS("http://109.111.185.150:9030/ws"),
  isConnected: false,
  message: {},
  messageDimmer: {},
  reconnectError: false,
  stompClient: null,
  recInterval: null,
};
export const new_conn = function () {
  if (!state.isConnected) {
    var newSoket = new SockJS("https://timers.autosystem.su/ws");
    // var newSoket = new SockJS("http://109.111.185.150:9030/ws");
    state.stompClient = Stomp.over(newSoket, {
      debug: false,
    });
    var headers = { "user-id": auth.state.userInfo.id };
    state.stompClient.connect(
      headers,
      (frame) => {
        console.log(frame, "Соединение восстановлено");
        state.recInterval = null;
        state.isConnected = true;
        state.stompClient.subscribe("/topic/dimmer_message_front", (tick) => {
          // console.log("Подписка на блоки демирования");
          const messageDimmer = JSON.parse(tick.body);
          state.messageDimmer = messageDimmer;
          let newElem = demir.state.allDemir.find((element) => {
            return element.dimmerId === messageDimmer.id;
          });

          var indexNewElem = demir.state.allDemir.indexOf(newElem);

          if (messageDimmer.currentPower !== null) {
            demir.state.allDemir[indexNewElem].currentPower =
              messageDimmer.currentPower;
          }
          if (messageDimmer.coordinate !== null) {
            demir.state.allDemir[indexNewElem].coordinate =
              messageDimmer.coordinate;
          }
          if (messageDimmer.intervalUpdateRadioSignal !== null) {
            demir.state.allDemir[indexNewElem].intervalUpdateRadioSignal =
              messageDimmer.intervalUpdateRadioSignal;
          }
          if (messageDimmer.lastActivity !== null) {
            demir.state.allDemir[indexNewElem].lastActivity =
              messageDimmer.lastActivity;
          }
          if (messageDimmer.powerRadioSignal !== null) {
            demir.state.allDemir[indexNewElem].powerRadioSignal =
              messageDimmer.powerRadioSignal;
          }
          if (messageDimmer.rssi !== null) {
            demir.state.allDemir[indexNewElem].rssi = messageDimmer.rssi;
          }
          if (messageDimmer.temperature !== null) {
            demir.state.allDemir[indexNewElem].temperature =
              messageDimmer.temperature;
          }
          if (messageDimmer.volt !== null) {
            demir.state.allDemir[indexNewElem].volt = messageDimmer.volt;
          }
        });
        //Подписка на сообщения от блоков таймера
        state.stompClient.subscribe(
          "/user/queue/timer_message_front",
          (tick) => {
            // console.log("Подписка на блоки таймера", tick);

            const messageTimer = JSON.parse(tick.body);
            state.message = messageTimer;

            let newElem = lights.state.lights.find((element) => {
              return element.id === messageTimer.id;
            });

            var indexNewElem = lights.state.lights.indexOf(newElem);
            if (messageTimer.status !== null) {
              lights.state.lights[indexNewElem].status = messageTimer.status;
            }
            if (messageTimer.securityDoor !== null) {
              lights.state.lights[indexNewElem].securityDoor =
                messageTimer.securityDoor;
            }
            if (messageTimer.currentTime !== null) {
              lights.state.lights[indexNewElem].currentTime =
                messageTimer.currentTime;
            }
            if (messageTimer.startTime !== null) {
              lights.state.lights[indexNewElem].startTime =
                messageTimer.startTime;
            }
            if (messageTimer.endTime !== null) {
              lights.state.lights[indexNewElem].endTime = messageTimer.endTime;
            }
            if (messageTimer.levelSignal !== null) {
              lights.state.lights[indexNewElem].levelSignal =
                messageTimer.levelSignal;
            }
            if (messageTimer.temperature !== null) {
              lights.state.lights[indexNewElem].temperature =
                messageTimer.temperature;
            }
            if (messageTimer.lastActivityTime) {
              lights.state.lights[indexNewElem].lastActivityTime =
                messageTimer.lastActivityTime;
            }
            if (messageTimer.coordinate !== null) {
              lights.state.lights[indexNewElem].coordinate =
                messageTimer.coordinate;
            }
            if (messageTimer.activatedStatus) {
              lights.state.lights[indexNewElem].activatedStatus =
                messageTimer.activatedStatus;
            }
            if (messageTimer.phone !== null) {
              lights.state.lights[indexNewElem].phone = messageTimer.phone;
            }
          }
        );
      },
      (error) => {
        state.isConnected = false;
        console.log(error, "❌ Socket Error!!!");
      }
    );
  }
};
export const mutationTypes = {
  SOCKET_ONOPEN: "✅ Socket connected!",
  SOCKET_ONCLOSE: "❌ Socket disconnected!",
  SOCKET_ONCLOSE_EXIT: "❌ Socket disconnected exit!",
  SOCKET_ONERROR: "❌ Socket Error!!!",
  SOCKET_SENDMESSAGE: "Socket Send Message",
  SOCKET_ONMESSAGE: "Websocket message received timer",
  SOCKET_ONMESSAGE_DIMMER: "Websocket message received dimmer",
  SOCKET_RECONNECT: "Websocket reconnected",
  SOCKET_RECONNECT_ERROR: "Websocket is having issues reconnecting..",
};
export const actionTypesSocket = {
  socketConnect: "[socket] socketConnect",
  // socketSendMessage: "[soket]socketSendMessage",
  socketAsseptMessage: "[socket] socketAsseptMessage",
  socketClose: "[socket] socketClose",
};

const mutations = {
  [mutationTypes.SOCKET_ONMESSAGE](state, messageTimer) {
    // console.log("ПОЛУЧИЛ СООБЩЕНИЕ ОТ ТАЙМЕРА", messageTimer);
    state.message = messageTimer;
    let newElem = lights.state.lights.find((element) => {
      return element.id === messageTimer.id;
    });
    console.log(newElem, "Найденный элемент");
    var indexNewElem = lights.state.lights.indexOf(newElem);
    if (messageTimer.status !== null) {
      lights.state.lights[indexNewElem].status = messageTimer.status;
    }
    if (messageTimer.securityDoor !== null) {
      lights.state.lights[indexNewElem].securityDoor =
        messageTimer.securityDoor;
    }
    if (messageTimer.currentTime !== null) {
      lights.state.lights[indexNewElem].currentTime = messageTimer.currentTime;
    }
    if (messageTimer.startTime !== null) {
      lights.state.lights[indexNewElem].startTime = messageTimer.startTime;
    }
    if (messageTimer.endTime !== null) {
      lights.state.lights[indexNewElem].endTime = messageTimer.endTime;
    }
    if (messageTimer.levelSignal !== null) {
      lights.state.lights[indexNewElem].levelSignal = messageTimer.levelSignal;
    }
    if (messageTimer.temperature !== null) {
      lights.state.lights[indexNewElem].temperature = messageTimer.temperature;
    }
    if (messageTimer.lastActivityTime) {
      lights.state.lights[indexNewElem].lastActivityTime =
        messageTimer.lastActivityTime;
    }
    if (messageTimer.activatedStatus) {
      lights.state.lights[indexNewElem].activatedStatus =
        messageTimer.activatedStatus;
    }
    if (messageTimer.coordinate !== null) {
      lights.state.lights[indexNewElem].coordinate = messageTimer.coordinate;
    }
  },
  [mutationTypes.SOCKET_ONMESSAGE_DIMMER](state, messageDimmer) {
    // console.log("ПОЛУЧИЛ СООБЩЕНИЕ ОТ ДИММЕРА", messageDimmer);
    state.messageDimmer = messageDimmer;
    let newElem = demir.state.allDemir.find((element) => {
      return element.dimmerId === messageDimmer.id;
    });

    var indexNewElem = demir.state.allDemir.indexOf(newElem);

    if (messageDimmer.currentPower !== null) {
      demir.state.allDemir[indexNewElem].currentPower =
        messageDimmer.currentPower;
    }
    if (messageDimmer.coordinate !== null) {
      demir.state.allDemir[indexNewElem].coordinate = messageDimmer.coordinate;
    }
    if (messageDimmer.intervalUpdateRadioSignal !== null) {
      demir.state.allDemir[indexNewElem].intervalUpdateRadioSignal =
        messageDimmer.intervalUpdateRadioSignal;
    }
    if (messageDimmer.lastActivity !== null) {
      demir.state.allDemir[indexNewElem].lastActivity =
        messageDimmer.lastActivity;
    }
    if (messageDimmer.powerRadioSignal !== null) {
      demir.state.allDemir[indexNewElem].powerRadioSignal =
        messageDimmer.powerRadioSignal;
    }
    if (messageDimmer.rssi !== null) {
      demir.state.allDemir[indexNewElem].rssi = messageDimmer.rssi;
    }
    if (messageDimmer.temperature !== null) {
      demir.state.allDemir[indexNewElem].temperature =
        messageDimmer.temperature;
    }
    if (messageDimmer.volt !== null) {
      demir.state.allDemir[indexNewElem].volt = messageDimmer.volt;
    }
  },
  [mutationTypes.SOCKET_ONOPEN](state) {
    state.isConnected = true;
    console.log("✅ Socket connected!");
  },
  [mutationTypes.SOCKET_ONCLOSE](state, event) {
    state.isConnected = false;
    console.log(event, "❌ Socket disconnected!");
    state.recInterval = window.setInterval(function () {
      new_conn();
    }, 5000);
    console.log(event, "❌ Socket disconnected!");
  },
  [mutationTypes.SOCKET_ONERROR](event) {
    state.isConnected = false;
    console.log(event);
  },
  // mutations for reconnect methods
  [mutationTypes.SOCKET_RECONNECT](state, count) {
    console.info(state, count);
  },
  [mutationTypes.SOCKET_RECONNECT_ERROR](state) {
    state.socket.reconnectError = true;
  },
  [mutationTypes.SOCKET_ONCLOSE_EXIT](state) {
    state.isConnected = false;
    state.socket.onclose = function () {
      console.log(event, "❌ Socket disconnected exit!");
    };
    state.stompClient.disconnect();
  },
};
const actions = {
  [actionTypesSocket.socketClose](context) {
    context.commit(mutationTypes.SOCKET_ONCLOSE_EXIT);
  },
  [actionTypesSocket.socketConnect](context, message) {
    if (state.isConnected) return;
    console.log("Начал попытку соединения...");
    // var newSoket = new SockJS("http://109.111.185.150:9030/ws");
    var newSoket = new SockJS("https://timers.autosystem.su/ws");
    state.stompClient = Stomp.over(newSoket, {
      debug: false,
    });
    var headers = { "user-id": message };
    state.stompClient.connect(
      headers,
      () => {
        context.commit(mutationTypes.SOCKET_ONOPEN);
        //Подписка на сообщения от блоков таймера
        state.stompClient.subscribe(
          "/user/queue/timer_message_front",
          (tick) => {
            // console.log("Подписка на блоки таймера", tick);

            const itemMessageTimer = JSON.parse(tick.body);
            context.commit(mutationTypes.SOCKET_ONMESSAGE, itemMessageTimer);
          }
        );
        //Подписка на сообщения от блоков демирования
        state.stompClient.subscribe("/topic/dimmer_message_front", (tick) => {
          // console.log("Подписка на блоки демирования");
          const itemMessageDimmer = JSON.parse(tick.body);
          context.commit(
            mutationTypes.SOCKET_ONMESSAGE_DIMMER,
            itemMessageDimmer
          );
        });
      },
      (error) => {
        console.log(error, "❌ Socket Error!!!");
        context.commit(mutationTypes.SOCKET_ONCLOSE);

        // setTimeout(this.dispatch(actionTypesSocket.socketConnect), 1000);
      }
    );
  },
};
export default {
  state,
  mutations,
  actions,
};
