import axios from "@/api/axios";
//получение всех блоков демирования без доп инфы
const getAllDemir = () => {
  return axios.get("get/all/dimmer");
};
//изменение имени блока демирования
const editNameDemir = (credentials) => {
  return axios.post("edit/name/dimmer", credentials);
};
//получение новых данных одного объекта
const getNewDemir = (credentials) => {
  return axios.get("get/dimmer" + "?dimmerId=" + credentials);
};
//получение доп параметров по id
const getProperty = (credentials) => {
  return axios.get("get/property" + "?dimmerId=" + credentials);
};
const postSetSettings = (credentials) => {
  return axios.post("set/settings", credentials);
};
const postSetAllSettings = (credentials) => {
  return axios.post("/set/list/settings", credentials);
};
export default {
  getAllDemir,
  editNameDemir,
  getNewDemir,
  getProperty,
  postSetSettings,
  postSetAllSettings,
};
