<template>
  <div>
    <!-- Modal Structure -->
    <div id="modalDelete" class="modal">
      <div class="modal-content">
        <div class="modal-info__title-block mint-green-color">
          <p class="modal-info__title-mini">Вы действительно хотите удалить?</p>
        </div>
      </div>
      <div class="modal-footer modal-mini">
        <button
          type="submit"
          name="btn_login"
          @click="deleteItemTimer"
          class="modal-close col s12 btn waves-effect mint-green-color"
        >
          Да
        </button>
        <button
          type="submit"
          name="btn_login"
          class="modal-close col s12 btn waves-effect mint-green-color"
        >
          Нет
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import { actionTypes } from "@/store/modules/lights";

export default {
  name: "AppModalDelete",
  data() {
    return {};
  },
  props: {
    item_light: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    handleClick() {
      this.modalInstance.close();
    },
    deleteItemTimer() {
      console.log("Удалить");

      this.$store
        .dispatch(actionTypes.deleteTimer, this.item_light.id)
        .then(() => {
          this.$store
            .dispatch(actionTypes.getList)
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },

  mounted() {
    const modal = document.querySelector(".modal");
    this.modalInstance = M.Modal.init(modal);
  },
};
</script>
<style scoped>
.modal {
  width: 20% !important;
}
.modal .modal-content {
  padding: 0px;
}
.modal .modal-footer {
  height: initial !important;
}
</style>
