<template>
  <l-popup ref="features">
    <div class="map-info__wrap">
      <div class="map-info__header">
        <div class="map-info__block-title">
          <ul id="dropdownMapPopup" class="dropdown-content">
            <li
              class="modal-trigger"
              data-target="modal1"
              style="display: flex; align-items: center"
            >
              <span class="size-text-14 grey-text text-darken-4"
                >Запрос состояния</span
              >
            </li>

            <hr />
            <li
              class="modal-trigger"
              data-target="modal4"
              style="display: flex; align-items: center"
            >
              <!-- <a class="">Настройки таймера</a> -->
              <span class="size-text-14 grey-text text-darken-4"
                >Настройки таймера</span
              >
            </li>
          </ul>
          <i
            class="material-icons dropdown-trigger"
            style="color: black; cursor: pointer"
            data-target="dropdownMapPopup"
            role="button"
            aria-label="Menu"
            @click="itemData()"
            >more_vert</i
          >
          <!-- data-position="bottom" -->
          <!-- data-tooltip="Меню устройства" -->
          <span
            class="map-info__text-title tooltipped"
            data-position="bottom"
            data-tooltip="IMEI таймера"
          >
            {{ this.item_light.imei }}</span
          >
        </div>
        <div
          class="map-info__block-svg modal-trigger tooltipped"
          data-target="modalChange"
          data-position="bottom"
          @click="itemData()"
          data-tooltip="Изменение координат"
        >
          <svg
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 136.039 136.039"
            style="enable-background: new 0 0 136.039 136.039 map-info__map"
          >
            <g>
              <g>
                <path
                  style="fill: #010002"
                  d="M107.022,74.78h-6.432c-1.712,2.846-3.52,5.585-5.394,8.139h6.749l21.057,43.117H13.026
			l21.063-43.117h6.749c-1.874-2.572-3.682-5.311-5.4-8.139h-6.432L0,134.174h136.039L107.022,74.78z"
                />
                <path
                  style="fill: #010002"
                  d="M68.016,1.865c-21.23,0-38.439,17.214-38.439,38.439c0,33.307,38.439,64.06,38.439,64.06
			s38.439-30.753,38.439-64.06C106.449,19.079,89.246,1.865,68.016,1.865z M68.016,55.692c-8.479,0-15.377-6.886-15.377-15.383
			c0-8.485,6.898-15.371,15.377-15.371c8.473,0,15.377,6.886,15.377,15.371C83.387,48.8,76.489,55.692,68.016,55.692z"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <p class="map-info__text">
        Широта/долгота:
        <span
          v-for="(coordinate, index) in this.item_light.coordinate"
          :key="index"
        >
          {{ coordinate }}</span
        >
      </p>
      <p class="map-info__text">
        Адрес:
        <span
          class="tooltipped"
          data-position="bottom"
          enterDelay="600"
          exitDelay="200"
          :data-tooltip="this.place"
          >{{ this.place | truncate(70, "...") }}</span
        >
      </p>
    </div>
    <a
      class="btn-floating halfway-fab waves-effect waves-light mint-green-color"
      @click="openInfoTimer"
      ><i class="material-icons"> expand_more</i></a
    >
    <!-- rss_feed -->
    <template v-if="!this.isOpenInfo">
      <hr />
      <div class="card-info-close brown lighten-5">
        <i
          class="material-icons tooltipped"
          data-position="bottom"
          :data-tooltip="'Счетчик смс: ' + this.item_light.smsCounter"
          >sms</i
        >
        <!-- <a class="btn-floating green"
          > -->
        <i
          v-if="this.item_light.securityDoor"
          class="material-icons tooltipped"
          data-position="bottom"
          :data-tooltip="'Дверь закрыта'"
          >lock_outline</i
        >
        <i
          v-if="!this.item_light.securityDoor"
          class="material-icons red-text tooltipped"
          data-position="bottom"
          :data-tooltip="'Дверь открыта'"
          >lock_open</i
        >
        <!-- </a> -->
        <!-- <i
          class="material-icons tooltipped"
          data-position="bottom"
          :data-tooltip="'Баланс на счету: ' + '200₽'"
          >account_balance_wallet</i
        > -->
        <div class="card-info-close__prop">
          <i class="material-icons">rss_feed</i>
          <span>{{ this.item_light.levelSignal }}</span>
        </div>
        <div class="card-info-close__prop">
          <svg
            class=""
            enable-background="new 0 0 24 24"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <g><rect fill="none" height="24" width="24" /></g>
            <g>
              <path
                fill="red"
                d="M11,12V6c0-1.66-1.34-3-3-3S5,4.34,5,6v6c-1.21,0.91-2,2.37-2,4c0,1.12,0.38,2.14,1,2.97V19h0.02c0.91,1.21,2.35,2,3.98,2 s3.06-0.79,3.98-2H12v-0.03c0.62-0.83,1-1.85,1-2.97C13,14.37,12.21,12.91,11,12z M5,16c0-0.94,0.45-1.84,1.2-2.4L7,13V6 c0-0.55,0.45-1,1-1s1,0.45,1,1v7l0.8,0.6c0.75,0.57,1.2,1.46,1.2,2.4H5z M18.62,4h-1.61l-3.38,9h1.56L16,10.7h3.63l0.8,2.3H22 L18.62,4z M16.47,9.39l1.31-3.72h0.08l1.31,3.72H16.47z"
              />
            </g>
          </svg>
          <span>{{ this.item_light.temperature + "°C" }}</span>
        </div>
      </div>
    </template>
    <!-- <transition name="fade"> -->
    <MapPopupInfo v-if="this.isOpenInfo" v-bind:item_light="item_light" />
    <!-- </transition> -->
    <a
      v-if="this.isOpenInfo"
      class="btn-floating halfway-fab waves-effect waves-light mint-green-color"
      @click="openInfoTimer"
      ><i class="material-icons">expand_less</i></a
    >
  </l-popup>
</template>

<script>
import M from "materialize-css";
import {
  LPopup,
  // LLayerGroup,
} from "vue2-leaflet";
import MapPopupInfo from "../components/MapPopupInfo";
export default {
  name: "AppMapPopup",
  data() {
    return {
      modalInstance: null,
      modalTooltip: null,
      modalTooltips: null,
      modalPopup: null,
      isOpenInfo: false,
    };
  },
  props: {
    item_light: {
      type: Object,
      default() {
        return {};
      },
    },
    place: {
      type: String,
      default() {
        return {};
      },
    },
  },

  components: {
    LPopup,
    MapPopupInfo,
  },
  methods: {
    openInfoTimer() {
      this.isOpenInfo = !this.isOpenInfo;
    },
    itemData() {
      console.log(this.item_light, "mapPopup");
      this.$emit("itemData", this.item_light);
    },
  },
  filters: {
    truncate: function (text, length, clamp) {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
  },
  mounted() {
    const elems = document.querySelectorAll(".dropdown-trigger");
    this.modalPopup = M.Dropdown.init(elems);

    var elemsTool = document.querySelectorAll(".tooltipped");
    this.modalTooltip = M.Tooltip.init(elemsTool);
  },
};
</script>

<style scoped>
.dropdown-content li {
  padding: 5px 8px 5px 8px !important;
}
/* 
#dropdownMapPopup {
  width: 218px !important;
  top: -35px;
  left: 69px !important ;
} */
</style>
