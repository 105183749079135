<template>
  <div class="">
    <div>
      <p class="modal-title">1. Время работы таймера:</p>
      <div class="modal-timer__block-time">
        <div class="modal-timer__wrap-input">
          <p class="modal-add-block__text">Включение</p>
          <div class="modal-timer__wrap-input-svg">
            <svg viewBox="0 0 64 64" class="modal-timer__svg">
              <g data-name="katman 2">
                <path
                  fill="none"
                  stroke="#434a54"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M23 51v-4.56M43.87 40.83A7.26 7.26 0 0041 46.44"
                />
                <path
                  fill="#ecf0f1"
                  stroke="#434a54"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M35 54.46V57a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2.54a4 4 0 0 0 2 .54h2a4 4 0 0 0 2-.54z"
                />
                <path
                  fill="#ecf0f1"
                  stroke="#434a54"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M37 47v4a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4v-4z"
                />
                <path
                  fill="#f1c40f"
                  stroke="#434a54"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M47 25.82a15 15 0 0 1-5.63 11.89A11.24 11.24 0 0 0 37 46.44V47H27v-.56a10.65 10.65 0 0 0-4.08-8.5A15 15 0 1 1 47 25.82z"
                />
                <path
                  fill="none"
                  stroke="#434a54"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M30 47V35M34 47V35M30 35l-6-6M34 35l6-6M26 15.61A11.94 11.94 0 0132 14M20 26a12 12 0 012.57-7.42M32 8V4M19.27 13.27l-2.83-2.83M14 26h-4M50 26h4M44.73 13.27l2.83-2.83M27 50h10"
                />
                <path fill="none" d="M0 0h64v64H0z" />
              </g>
            </svg>
            <!-- v-model="this.item_light.startTime" -->
            <vue-timepicker
              format="HH:mm:ss"
              name="startTimeOn"
              v-model="startTime"
            ></vue-timepicker>
          </div>
        </div>
        <div class="modal-timer__wrap-input">
          <p class="modal-add-block__text">Выключение</p>
          <div class="modal-timer__wrap-input-svg">
            <svg viewBox="0 0 64 64" class="modal-timer__svg">
              <g data-name="katman 2">
                <path
                  fill="none"
                  stroke="#434a54"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M23 51v-4.56M43.87 40.83A7.26 7.26 0 0041 46.44M35 54.46V57a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2.54a4 4 0 002 .54h2a4 4 0 002-.54z"
                />
                <path
                  fill="none"
                  stroke="#434a54"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M37 47v4a4 4 0 01-4 4h-2a4 4 0 01-4-4v-4zM47 25.82a15 15 0 01-5.63 11.89A11.24 11.24 0 0037 46.44V47H27v-.56a10.65 10.65 0 00-4.08-8.5A15 15 0 1147 25.82zM30 47V35M34 47V35M30 35l-6-6M34 35l6-6M26 15.61A11.94 11.94 0 0132 14M20 26a12 12 0 012.57-7.42M32"
                />
                <path fill="none" d="M0 0h64v64H0z" />
              </g>
            </svg>
            <vue-timepicker
              format="HH:mm:ss"
              name="endTimeOff"
              v-model="endTime"
            ></vue-timepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="modal-add-block__title-with-btn">
        <p class="modal-title">2. Установите координаты:</p>
        <div class="modal-timer__title-with-btn" v-if="!isCopyCoordinate">
          <button
            class="btn waves-effect waves-light mint-green-color"
            name="action"
            @click="pasteCoordinate"
            type="button"
          >
            Вставить из буфера
            <i class="material-icons right">send</i>
          </button>
        </div>
        <div class="modal-timer__title-with-btn" v-else>
          <button
            class="btn waves-effect waves-light mint-green-color"
            name="action"
            @click="pasteCoordinate"
            type="button"
          >
            Вернуть
            <i class="material-icons right">send</i>
          </button>
        </div>
      </div>
      <p
        v-if="isCopyCoordinate && this.copyCoordinate === null"
        class="modal-title__error red-text text-darken-1"
      >
        Координаты не скопированы*
      </p>
      <p
        v-if="isCopyCoordinate && this.copyCoordinate !== null"
        class="modal-title__error green-text text-darken-1"
      >
        Координаты успешно скопированы!
      </p>
      <div
        class=""
        v-if="
          !isCopyCoordinate ||
          (isCopyCoordinate && this.copyCoordinate === null)
        "
      >
        <div class="input-field col s6">
          <p>Широта</p>
          <input
            id="icon_lat"
            type="text"
            name="latitude"
            class="validate"
            v-model="latitude"
          />
        </div>
        <div class="input-field col s6">
          <p>Долгота</p>

          <input
            id="icon_lon"
            type="text"
            name="longitude"
            class="validate"
            v-model="longitude"
          />
        </div>
      </div>
      <div class="" v-if="isCopyCoordinate && this.copyCoordinate !== null">
        <div class="input-field col s6">
          <p>Широта</p>
          <input
            id="icon_lat"
            type="text"
            name="latitude"
            class="validate"
            v-bind:value="copyCoordinate[0].lat"
          />
        </div>
        <div class="input-field col s6">
          <p>Долгота</p>

          <input
            id="icon_lon"
            type="text"
            name="longitude"
            class="validate"
            v-bind:value="copyCoordinate[0].lng"
          />
        </div>
      </div>
    </div>
    <div class="">
      <p class="modal-title">3. Номер SIM:</p>
      <div class="input-field">
        <i class="material-icons prefix">phone</i>
        <input
          id="icon_telephone"
          type="tel"
          name="phone"
          class="validate"
          v-model="phone"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import { actionTypes } from "@/store/modules/lights";
import { mapState } from "vuex";
export default {
  name: "AppComponentSettingsTimer",
  data() {
    return {
      startTime: "",
      endTime: "",
      isCopyCoordinate: false,
      latitude: "",
      longitude: "",
      phone: "",
      allSettings: {},
    };
  },
  methods: {
    pasteCoordinate() {
      this.isCopyCoordinate = !this.isCopyCoordinate;
      console.log("paste", this.copyCoordinate);
    },
    submitData(info) {
      if (this.isCopyCoordinate && this.copyCoordinate !== null) {
        this.allSettings = {
          IMEI: info,
          startTimeOn: this.startTime,
          endTimeOff: this.endTime,
          phone: this.phone,
          latitude: this.copyCoordinate[0].lat,
          longitude: this.copyCoordinate[0].lng,
        };
      } else {
        this.allSettings = {
          IMEI: info,
          startTimeOn: this.startTime,
          endTimeOff: this.endTime,
          phone: this.phone,
          latitude: this.latitude,
          longitude: this.longitude,
        };
      }

      console.log(this.allSettings);

      this.$store
        .dispatch(actionTypes.addTimer, this.allSettings)
        .then(() => {
          console.log("Запрос прошел");
          this.$emit("handleClick");
          this.startTime = "";
          this.endTime = "";
          this.phone = "";
          this.latitude = "";
          this.longitude = "";
          // alert("Устройство добавлено");
          this.$store
            .dispatch(actionTypes.getList)
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
  components: {
    VueTimepicker,
  },
  computed: {
    ...mapState({
      copyCoordinate: (state) => state.lights.copyCoordinate,
    }),
  },
};
</script>

<style></style>
